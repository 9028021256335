#cart-sidepanel {
	display: flex;
	flex-flow: column;
	height: 100%;
	width: 500px;
	position:fixed;
	z-index:100600;
	right: -501px;
	top:0;
	background-color: #FFFFFF;

	@media (max-width: 767px){
		width: 300px;
		right: -301px;
	}

	// mind you,that the transition speed is also used in chechout.js
	-webkit-transition: all 300ms ease-in-out;
	-moz-transition: all 300ms ease-in-out;
	-ms-transition: all 300ms ease-in-out;
	-o-transition: all 300ms ease-in-out;
	transition: all 300ms ease-in-out;

	.drn-icon {
		display: block;
		position: absolute;
		right: 0;
		top: 0;
		cursor: pointer;
	}

	#cart-sidepanel-header {
		padding:15px;
	}

	#cart-sidepanel-body {
		flex: 1;
		padding:15px;
		overflow: auto;
	}

	#cart-sidepanel-footer {
		padding:0px;
	}

}

#cart-sidepanel.show {
	-webkit-box-shadow: -3px 0 4px 0 rgba(0,0,0,0.2);
	box-shadow: -3px 0 4px 0 rgba(0,0,0,0.2);
	right: 0px;

	&:after {
	    content: "";
	    display: block;
	    position: absolute;
	    left: -100vw;
	    top: 0;
	    width: 100vw;
	    height: 100vh;
	    background-color: rgba(0,0,0,.2);
	}
}

#cart-sidepanel-title {
	padding-left:15px;
}

// specific papertype styling
.cart-sidepanel-papertype {
	display: block;
    position: relative;
	border-bottom: 1px #f2f2f2 solid;

	.selected {
		display: block;
		position: absolute;
		left:0;
		top: -1em;
	    width: 100%;
	    height: calc(100% + 1em);
		border-top:2px #9fc58a solid;
		border-bottom:2px #9fc58a solid;

		&:after {
			content: '';
		    display: block;
		    position: absolute;
		    right: 0px;
		    top: 5px;
		    width: 20px;
		    height: 20px;
		    -webkit-border-radius: 50%;
		    border-radius: 50%;
		    background-color: #9fc58a;
		    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJMYWFnXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDI1IDE5Ij48cGF0aCBkPSJtMjQuNTMuNDljLS4zLS4zMi0uNzktLjMyLTEuMDksMGwtMTUuMzYsMTYuMzNMMS41Niw5Ljg4Yy0uMy0uMzItLjc5LS4zMi0xLjA5LDAtLjI5LjMxLS4yOS44LDAsMS4xMWw3LjA3LDcuNTFjLjE1LjE2LjM0LjI0LjU0LjI0cy40LS4wOC41NC0uMjRMMjQuNTMsMS42Yy4yOS0uMzEuMjktLjgsMC0xLjExWiIgZmlsbD0iI2ZmZiIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9Ii41Ii8+PC9zdmc+);
		    background-position: center 44%;
		    background-repeat: no-repeat;
		    background-size: 58%;
		}
	}

	.cart-sidepanel-papertype-img {
		display: block;
		position: relative;
		width: 30%;
		float:left;
		background-color: #f3f3f3;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
	}
	.cart-sidepanel-papertype-title,
	.cart-sidepanel-papertype-description {
		display: block;
		position: relative;
		padding-left: 1em;
		width: 70%;
		float:right;
	}

	.cart-sidepanel-papertype-title {
		font-weight: bold;
		margin-bottom: 0.2em;
	}

	.cart-sidepanel-papertype-description {
		line-height: 1.3em;
	}
}