/*
different layout based on settings
- default: mockupstyle_default (category-product-image mockupstyle_default)
- default: mockupstyle_centered (category-product-image mockupstyle_centered)
*/


.card_holder {
	display: block;
	position: absolute;
	left:0;
	top:0;
	width: 100%;
	height: 100%;
}

.category_image_wrapper:not(.disabledropshadow) .card_single_front,
.category_image_wrapper:not(.disabledropshadow) .card_spread_tweeluik,
.product_image_wrapper:not(.disabledropshadow) .card_double_front,
.product_image_wrapper:not(.disabledropshadow) .card_double_inside,
.cart_image_wrapper:not(.disabledropshadow) .card_double_front,
.cart_image_wrapper:not(.disabledropshadow) .card_double_inside {
    filter: drop-shadow(2px 2px 3px rgba(0,0,0,.2));
}

.category_image_wrapper {
	display: block;
	position: absolute;
	left:0;
	top:0;
	width: 100%;
	height: 100%;
}

.category_image_element {
	display: block;
	position: absolute;
	left:0;
	top:0;
	width: 100%;
	height: 100%;
}

.card_holder_slick_product,
.card_holder_slick_category,
.card_holder_slick_category_cart {
	width: 100%;
	height: 100%;	

	.slick-list {
		height: 100%;
		overflow: visible;
	}

	.slick-track {
		height: 100%;
	}

	.slick-slide  > div {
		display: block;
		position: relative;
		height: 100%;
	}
}

.slider-product-prev,
.slider-product-next {
	display: block;
	position: absolute;
	z-index: 100;
	top:0;
	width: 20px;
	height: 100%;
	background-color: transparent;
	border:none;
}

.slider-product-prev {
	left:0;
	-moz-transform: rotate(180deg);
	-webkit-transform: rotate(180deg);
	-o-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
		transform-origin: center;
}

.slider-product-next {
	right:0;
}

.slider-product-prev.slick-disabled,
.slider-product-next.slick-disabled {
	visibility: hidden;
}

.slider-product-prev .drn-icon-button-arrow,
.slider-product-next .drn-icon-button-arrow {
	display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

/* -------------- shared ------------------ */

.card_fx {
	display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left:50%;
    z-index: 10;
    background-color: #000000;

	/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.1+100 */
	/* IE9 SVG, needs conditional override of 'filter' to 'none' */
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMTAwJSI+CiAgICA8c3RvcCBvZmZzZXQ9IjAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwLjEiLz4KICA8L2xpbmVhckdyYWRpZW50PgogIDxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9InVybCgjZ3JhZC11Y2dnLWdlbmVyYXRlZCkiIC8+Cjwvc3ZnPg==);
	background: -moz-linear-gradient(-45deg,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.1) 100%); /* FF3.6-15 */
	background: -webkit-gradient(linear, left top, right bottom, color-stop(0%,rgba(0,0,0,0)), color-stop(100%,rgba(0,0,0,0.1))); /* Chrome4-9,Safari4-5 */
	background: -webkit-linear-gradient(-45deg,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.1) 100%); /* Chrome10-25,Safari5.1-6 */
	background: -o-linear-gradient(-45deg,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.1) 100%); /* Opera 11.10-11.50 */
	background: -ms-linear-gradient(-45deg,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.1) 100%); /* IE10 preview */
	background: linear-gradient(135deg,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#1a000000',GradientType=1 ); /* IE6-8 fallback on horizontal gradient */

	background-blend-mode: multiply;

    -moz-transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
}

.card_fx_first_back {
	display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left:50%;
    z-index: 10;
    background-color: #000000;

	/* IE9 SVG, needs conditional override of 'filter' to 'none' */
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMTAwJSI+CiAgICA8c3RvcCBvZmZzZXQ9IjAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMiIvPgogICAgPHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
	background: -moz-linear-gradient(-45deg,  rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.1) 100%); /* FF3.6-15 */
	background: -webkit-gradient(linear, left top, right bottom, color-stop(0%,rgba(0,0,0,0.2)), color-stop(100%,rgba(0,0,0,0.1))); /* Chrome4-9,Safari4-5 */
	background: -webkit-linear-gradient(-45deg,  rgba(0,0,0,0.2) 0%,rgba(0,0,0,0.1) 100%); /* Chrome10-25,Safari5.1-6 */
	background: -o-linear-gradient(-45deg,  rgba(0,0,0,0.2) 0%,rgba(0,0,0,0.1) 100%); /* Opera 11.10-11.50 */
	background: -ms-linear-gradient(-45deg,  rgba(0,0,0,0.2) 0%,rgba(0,0,0,0.1) 100%); /* IE10 preview */
	background: linear-gradient(135deg,  rgba(0,0,0,0.2) 0%,rgba(0,0,0,0.1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#33000000', endColorstr='#1a000000',GradientType=1 ); /* IE6-8 fallback on horizontal gradient */

	background-blend-mode: multiply;

    -moz-transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
}

.card_fx_second_back {
	display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left:50%;
    z-index: 10;
    background-color: #000000;

	/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0.3+0,0.2+100 */
	/* IE9 SVG, needs conditional override of 'filter' to 'none' */
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMTAwJSI+CiAgICA8c3RvcCBvZmZzZXQ9IjAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMyIvPgogICAgPHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMiIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
	background: -moz-linear-gradient(-45deg,  rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.2) 100%); /* FF3.6-15 */
	background: -webkit-gradient(linear, left top, right bottom, color-stop(0%,rgba(0,0,0,0.3)), color-stop(100%,rgba(0,0,0,0.2))); /* Chrome4-9,Safari4-5 */
	background: -webkit-linear-gradient(-45deg,  rgba(0,0,0,0.3) 0%,rgba(0,0,0,0.2) 100%); /* Chrome10-25,Safari5.1-6 */
	background: -o-linear-gradient(-45deg,  rgba(0,0,0,0.3) 0%,rgba(0,0,0,0.2) 100%); /* Opera 11.10-11.50 */
	background: -ms-linear-gradient(-45deg,  rgba(0,0,0,0.3) 0%,rgba(0,0,0,0.2) 100%); /* IE10 preview */
	background: linear-gradient(135deg,  rgba(0,0,0,0.3) 0%,rgba(0,0,0,0.2) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4d000000', endColorstr='#33000000',GradientType=1 ); /* IE6-8 fallback on horizontal gradient */
	
	background-blend-mode: multiply;

    -moz-transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
}

.mockupstyle_default {

	.shade-circle-gradient {
		content:'';
		display: block;
		position: absolute;
		left:50%;
		bottom:-2px;
		width:100%;
		height:7px;
		z-index: -1;
		/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+46,000000+46,000000+100&1+0,1+46,0+100 */
		/* IE9 SVG, needs conditional override of 'filter' to 'none' */
		background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPHJhZGlhbEdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgY3g9IjUwJSIgY3k9IjUwJSIgcj0iNzUlIj4KICAgIDxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMSIvPgogICAgPHN0b3Agb2Zmc2V0PSI0NiUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMSIvPgogICAgPHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAiLz4KICA8L3JhZGlhbEdyYWRpZW50PgogIDxyZWN0IHg9Ii01MCIgeT0iLTUwIiB3aWR0aD0iMTAxIiBoZWlnaHQ9IjEwMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
		background: -moz-radial-gradient(center, ellipse cover,  rgba(0,0,0,1) 0%, rgba(0,0,0,1) 46%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
		background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,rgba(0,0,0,1)), color-stop(46%,rgba(0,0,0,1)), color-stop(100%,rgba(0,0,0,0))); /* Chrome4-9,Safari4-5 */
		background: -webkit-radial-gradient(center, ellipse cover,  rgba(0,0,0,1) 0%,rgba(0,0,0,1) 46%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
		background: -o-radial-gradient(center, ellipse cover,  rgba(0,0,0,1) 0%,rgba(0,0,0,1) 46%,rgba(0,0,0,0) 100%); /* Opera 12+ */
		background: -ms-radial-gradient(center, ellipse cover,  rgba(0,0,0,1) 0%,rgba(0,0,0,1) 46%,rgba(0,0,0,0) 100%); /* IE10 preview */
		background: radial-gradient(ellipse at center,  rgba(0,0,0,1) 0%,rgba(0,0,0,1) 46%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=1 ); /* IE6-8 fallback on horizontal gradient */

		-webkit-filter: blur(6px);
		filter: blur(6px);
		filter:progid:DXImageTransform.Microsoft.Blur(PixelRadius='6');

		-moz-transform: translateX(-50%);
		-webkit-transform: translateX(-50%);
		-o-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);

		opacity: 0.6;

	}

	.shade-circle-gradient-small {
		bottom:-2px;
		height: 4px;
		-webkit-filter: blur(2px);
		filter: blur(2px);
		filter:progid:DXImageTransform.Microsoft.Blur(PixelRadius='2');
		opacity: 0.3;
	}

}

.mockupstyle_centered {

	.shade-circle-gradient,
	.shade-circle-gradient-small {
		display: none;
	}
}

// .card-spread-foldline {
// 	display: block;
//     position: relative;
//     width: 1px;
//     z-index: 10;
//     background-color: #dadada;
// }

.category-product-image.disabledropshadow {

}

.category-product-image.disabledropshadow {
	
}

/* ---- ---- */

.card_additional_product_image {
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	right:0;
	top:0;
}

.card_holder img {
	height: auto;
    width: 100%;
}

.card_holder_media img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	// object-fit: contain;
	// padding-left: 12%;
	// padding-right: 12%;
}

body.product_v2 {
	.card_holder_media img {
		object-fit: contain;
		padding-left: 12%;
		padding-right: 12%;
	}
}

.mockupstyle_default {
	.card_holder_media img {
		object-position: center bottom;
	}
}

.mockupstyle_centered {
	.card_holder_media img {
		object-position: center center;
	}
}

.mockupstyle_default {
	&.disabledropshadow .card_fx {
		display: none;
	}
	&.disabledropshadow .shade-circle-gradient {
		display: none;
	}
}

.mockupstyle_centered {
	&.disabledropshadow .card_fx {
		display: none !important;
	}

	&:not(.disabledropshadow) .card_holder .card_side {
		filter: drop-shadow(2px 2px 3px rgba(0,0,0,.2));
		// -webkit-box-shadow: 0 0 5px 1px rgba(.5,0,0,0.1) !important;
		// box-shadow: 0 0 5px 1px rgba(.5,0,0,0.1) !important;
	}
}

/* -------------- single cards ------------------ */

.card_single_front {
	display: block;
	position: absolute;
}

.card_single_front img {
	display: block;
	position: relative;
	margin: 0 auto;
}

.product-image-block {
	.card_single_front {
		left:50%;
		top:50%;
		-moz-transform: translateX(-50%) translateY(-50%);
		-webkit-transform: translateX(-50%) translateY(-50%);
		-o-transform: translateX(-50%) translateY(-50%);
		-ms-transform: translateX(-50%) translateY(-50%);
		transform: translateX(-50%) translateY(-50%);
	}
}

.mockupstyle_default {
	.card_single_front {
		bottom:0;
		left:50%;
		-moz-transform: translateX(-50%);
		-webkit-transform: translateX(-50%);
		-o-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);
	}
}

.mockupstyle_centered {
	.card_single_front {
		bottom:50%;
		left:50%;
		-moz-transform: translateX(-50%) translateY(50%);
		-webkit-transform: translateX(-50%) translateY(50%);
		-o-transform: translateX(-50%) translateY(50%);
		-ms-transform: translateX(-50%) translateY(50%);
		transform: translateX(-50%) translateY(50%);
	}
}

// card single square

.card_single_front.orientation_square {
	width: 71%;
    max-width: 390px;
}

// card single landscape

.card_single_front.orientation_landscape {
	width: 70%;
    max-width: 470px;
}

// card single portrait

.card_single_front.orientation_portrait {
	width: 42%;
    max-width: 255px;
}

.f-100-150 .card_single_front.orientation_portrait {
	width: 50%;
}

// card single portrait tall

.card_single_front.orientation_portrait.portrait-semitall {
	width: 31%;
	max-width: 210px;
}

// card single portrait tall

.card_single_front.orientation_portrait.portrait-tall {
	width: 23%;
}

// card double square additional productoduct

.card_additional_product_image.card_single.orientation_square img {
	display: block;
	position:absolute;
	transform-origin: center;
	left:57%;
	top:46%;
	-moz-transform:  translateX(-50%) translateY(-50%);
	-webkit-transform:  translateX(-50%) translateY(-50%);
	-o-transform:  translateX(-50%) translateY(-50%);
	-ms-transform:  translateX(-50%) translateY(-50%);
	transform:  translateX(-50%) translateY(-50%);
	width: 59%; // same as card_double_front.orientation_square height
    max-width: 390px;
    height: auto;
	filter: drop-shadow(2px 2px 3px rgba(0,0,0,.2));
}

.card_additional_product_image.card_single.orientation_portrait img {
	display: block;
	position:absolute;
	transform-origin: center;
	left:57%;
	top:46%;
	-moz-transform:  translateX(-50%) translateY(-50%);
	-webkit-transform:  translateX(-50%) translateY(-50%);
	-o-transform:  translateX(-50%) translateY(-50%);
	-ms-transform:  translateX(-50%) translateY(-50%);
	transform:  translateX(-50%) translateY(-50%);
	width: 42%;
    max-width: 255px;
	filter: drop-shadow(2px 2px 3px rgba(0,0,0,.2));
}

.f-100-150 .card_additional_product_image.card_single.orientation_portrait img {
	width: 50%;
}

// card double portrait additional product
.card_additional_product_image.card_single.portrait-semitall img {
	display: block;
	position:absolute;
	transform-origin: center;
	left:57%;
	top:46%;
	-moz-transform:  translateX(-50%) translateY(-50%);
	-webkit-transform:  translateX(-50%) translateY(-50%);
	-o-transform:  translateX(-50%) translateY(-50%);
	-ms-transform:  translateX(-50%) translateY(-50%);
	transform:  translateX(-50%) translateY(-50%);
	width: 31%;
    max-width: 210px;
	filter: drop-shadow(2px 2px 3px rgba(0,0,0,.2));
}

// card double portrait additional product
.card_additional_product_image.card_single.orientation_landscape img {
	display: block;
	position:absolute;
	transform-origin: center;
	left:57%;
	top:46%;
	-moz-transform:  translateX(-50%) translateY(-50%);
	-webkit-transform:  translateX(-50%) translateY(-50%);
	-o-transform:  translateX(-50%) translateY(-50%);
	-ms-transform:  translateX(-50%) translateY(-50%);
	transform:  translateX(-50%) translateY(-50%);
	width: 70%;
    max-width: 470px;
	filter: drop-shadow(2px 2px 3px rgba(0,0,0,.2));
}


// ratio overwrites double square product detail page
.product_image_wrapper .mockupstyle_centered {

	// card double square
	.card_single_front.orientation_square {
		width: 42.5%;
	}

	// card double portrait
	.card_single_front.orientation_portrait {
		width: 42.5%;
		max-width: 255px;
	}
	.card_single_front.orientation_portrait.portrait-semitall {
		width: 31%;
		max-width: 210px;
	}

	// card double landscape
	.card_single_front.orientation_landscape {
		width: 42.5%;
	}
}


@media (max-width:767px){


}

/* -------------- double cards ------------------ */

.card_double_front,
.card_double_inside {
	display: block;
	position: absolute;
}

.card_double_inside {
	z-index: 5;
}

.card_double_front {
	z-index: 10;
}

.card_double_inside {
	-moz-transform-origin: top left;
	-webkit-transform-origin: top left;
	-o-transform-origin: top left;
	-ms-transform-origin: top left;
	transform-origin: top left;
}

.card_double_front {
	-moz-transform-origin: top left;
	-webkit-transform-origin: top left;
	-o-transform-origin: top left;
	-ms-transform-origin: top left;
	transform-origin: top left;
}

.card_double_inside img,
.card_double_front img {
	display: block;
	position: relative;
	margin: 0 auto;
}

.mockupstyle_default {
	.card_double_front,
	.card_double_inside {
		bottom:0;
		left:50%;
	}
	.card_double_inside {
		-moz-transform: translateX(-50%) skewY(-6deg) scaleX(0.95);
		-webkit-transform: translateX(-50%) skewY(-6deg) scaleX(0.95);
		-o-transform: translateX(-50%) skewY(-6deg) scaleX(0.95);
		-ms-transform: translateX(-50%) skewY(-6deg) scaleX(0.95);
		transform: translateX(-50%) skewY(-6deg) scaleX(0.95);

	}

	.card_double_front {
		-moz-transform: translateX(-50%) skewY(0deg);
		-webkit-transform: translateX(-50%) skewY(0deg);
		-o-transform: translateX(-50%) skewY(0deg);
		-ms-transform: translateX(-50%) skewY(0deg);
		transform: translateX(-50%) skewY(0deg);
	}
	.tweeluik.boven .card_double_inside {
		-moz-transform: translateX(-50%) skewX(6deg) scaleY(.95);
		-webkit-transform: translateX(-50%) skewX(6deg) scaleY(.95);
		-o-transform: translateX(-50%) skewX(6deg) scaleY(.95);
		-ms-transform: translateX(-50%) skewX(6deg) scaleY(.95);
		transform: translateX(-50%) skewX(6deg) scaleY(.95);
	}
}

.mockupstyle_centered {
	.card_double_front,
	.card_double_inside {
		bottom:50%;
		left:50%;
	}
	.card_double_inside {
		-moz-transform: translateX(-50%) translateY(50%) skewY(-6deg) scaleX(0.95);
		-webkit-transform: translateX(-50%) translateY(50%) skewY(-6deg) scaleX(0.95);
		-o-transform: translateX(-50%) translateY(50%) skewY(-6deg) scaleX(0.95);
		-ms-transform: translateX(-50%) translateY(50%) skewY(-6deg) scaleX(0.95);
		transform: translateX(-50%) translateY(50%) skewY(-6deg) scaleX(0.95);
	}

	.card_double_inside_reversed {
		-moz-transform-origin: top right;
		-webkit-transform-origin: top right;
		-o-transform-origin: top right;
		-ms-transform-origin: top right;
		transform-origin: top right;
		-moz-transform: translateX(-50%) translateY(50%) skewY(6deg) scaleX(0.95);
		-webkit-transform: translateX(-50%) translateY(50%) skewY(6deg) scaleX(0.95);
		-o-transform: translateX(-50%) translateY(50%) skewY(6deg) scaleX(0.95);
		-ms-transform: translateX(-50%) translateY(50%) skewY(6deg) scaleX(0.95);
		transform: translateX(-50%) translateY(50%) skewY(6deg) scaleX(0.95);
	}

	.card_double_front {
		-moz-transform: translateX(-50%) translateY(50%) skewY(0deg);
		-webkit-transform: translateX(-50%) translateY(50%) skewY(0deg);
		-o-transform: translateX(-50%) translateY(50%) skewY(0deg);
		-ms-transform: translateX(-50%) translateY(50%) skewY(0deg);
		transform: translateX(-50%) translateY(50%) skewY(0deg);
	}
	.tweeluik.boven .card_double_inside {
		-moz-transform: translateX(-50%) translateY(50%) skewX(6deg) scaleY(.95);
		-webkit-transform: translateX(-50%) translateY(50%) skewX(6deg) scaleY(.95);
		-o-transform: translateX(-50%) translateY(50%) skewX(6deg) scaleY(.95);
		-ms-transform: translateX(-50%) translateY(50%) skewX(6deg) scaleY(.95);
		transform: translateX(-50%) translateY(50%) skewX(6deg) scaleY(.95);
	}
}

// card double square

.card_double_inside.orientation_square,
.card_double_front.orientation_square {
	width: 71%;
}

// card double portrait
.card_double_inside.orientation_portrait,
.card_double_front.orientation_portrait {
	width: 45%;
}

// card double landscape
.card_double_inside.orientation_landscape,
.card_double_front.orientation_landscape {
	width: 85%;
}

// card double square additional productoduct
.card_additional_product_image.card_double.orientation_square img {
	display: block;
	position:absolute;
	transform-origin: center;
	left:57%;
	top:46%;
	-moz-transform:  translateX(-50%) translateY(-50%);
	-webkit-transform:  translateX(-50%) translateY(-50%);
	-o-transform:  translateX(-50%) translateY(-50%);
	-ms-transform:  translateX(-50%) translateY(-50%);
	transform:  translateX(-50%) translateY(-50%);
	width: 59%; // same as card_double_front.orientation_square height
    max-width: 390px;
    height: auto;
	filter: drop-shadow(2px 2px 3px rgba(0,0,0,.2));
}

// card double portrait additional product
.card_additional_product_image.card_double.orientation_portrait img {
	display: block;
	position:absolute;
	transform-origin: center;
	left:57%;
	top:46%;
	-moz-transform:  translateX(-50%) translateY(-50%);
	-webkit-transform:  translateX(-50%) translateY(-50%);
	-o-transform:  translateX(-50%) translateY(-50%);
	-ms-transform:  translateX(-50%) translateY(-50%);
	transform:  translateX(-50%) translateY(-50%);
	width: 42%;
    max-width: 255px;
	filter: drop-shadow(2px 2px 3px rgba(0,0,0,.2));
}

.f-100-150 .card_additional_product_image.card_double.orientation_portrait img {
	width: 50%;
}

// card double portrait additional product
.card_additional_product_image.card_double.portrait-semitall img {
	display: block;
	position:absolute;
	transform-origin: center;
	left:57%;
	top:46%;
	-moz-transform:  translateX(-50%) translateY(-50%);
	-webkit-transform:  translateX(-50%) translateY(-50%);
	-o-transform:  translateX(-50%) translateY(-50%);
	-ms-transform:  translateX(-50%) translateY(-50%);
	transform:  translateX(-50%) translateY(-50%);
	width: 31%;
    max-width: 210px;
	filter: drop-shadow(2px 2px 3px rgba(0,0,0,.2));
}

// card double portrait additional product
.card_additional_product_image.card_double.orientation_landscape img {
	display: block;
	position:absolute;
	transform-origin: center;
	left:57%;
	top:46%;
	-moz-transform:  translateX(-50%) translateY(-50%);
	-webkit-transform:  translateX(-50%) translateY(-50%);
	-o-transform:  translateX(-50%) translateY(-50%);
	-ms-transform:  translateX(-50%) translateY(-50%);
	transform:  translateX(-50%) translateY(-50%);
	width: 70%;
    max-width: 470px;
	filter: drop-shadow(2px 2px 3px rgba(0,0,0,.2));
}

.product_image_wrapper .mockupstyle_centered {

	// card double square
	.card_double_inside.orientation_square,
	.card_double_front.orientation_square {
		width: 59%;
		max-width: 390px;
	}

	// card double portrait
	.card_double_inside.orientation_portrait,
	.card_double_front.orientation_portrait {
		width: 42%;
		max-width: 255px;
	}

	.f-100-150 .card_double_inside.orientation_portrait,
	.f-100-150 .card_double_front.orientation_portrait {
		width: 50%;
	}

	// card double portrait semitall
	.card_double_inside.orientation_portrait.portrait-semitall,
	.card_double_front.orientation_portrait.portrait-semitall {
		width: 31%;
		max-width: 210px;
	}

	// card double landscape
	.card_double_inside.orientation_landscape,
	.card_double_front.orientation_landscape {
		width: 70%;
		max-width: 470px;
	}
}

@media (max-width:767px){

}

/* -------------- triple cards ------------------ */

.card_triple_front,
.card_triple_inside,
.card_triple_back, {
	display: block;
	position: absolute;
}

.card_triple_front {
	-moz-transform-origin: top right;
	-webkit-transform-origin: top right;
	-o-transform-origin: top right;
	-ms-transform-origin: top right;
	transform-origin: top right;
}

.card_triple_inside {
	-moz-transform-origin: top right;
	-webkit-transform-origin: top right;
	-o-transform-origin: top right;
	-ms-transform-origin: top right;
	transform-origin: top right;
}

.card_triple_back {
	-moz-transform-origin: top right;
	-webkit-transform-origin: top right;
	-o-transform-origin: top right;
	-ms-transform-origin: top right;
	transform-origin: top right;
}

.card_triple_back img,
.card_triple_inside img,
.card_triple_front img {
	display: block;
	position: relative;
	margin: 0 auto;
}

.mockupstyle_default {
	.card_triple_front,
	.card_triple_inside,
	.card_triple_back, {
		bottom:-1vw;
		left:50%;
	}
	.card_triple_front {
		-moz-transform: translateX(-50%) translateY(-10%);
		-webkit-transform: translateX(-50%) translateY(-10%);
		-o-transform: translateX(-50%) translateY(-10%);
		-ms-transform: translateX(-50%) translateY(-10%);
		transform: translateX(-50%) translateY(-10%);
	}

	.card_triple_inside {
		-moz-transform: translateX(-50%) translateY(-10%) skewY(4deg) scaleX(0.95);
		-webkit-transform: translateX(-50%) translateY(-10%) skewY(4deg) scaleX(0.95);
		-o-transform: translateX(-50%) translateY(-10%) skewY(4deg) scaleX(0.95);
		-ms-transform: translateX(-50%) translateY(-10%) skewY(4deg) scaleX(0.95);
		transform: translateX(-50%) translateY(-10%) skewY(4deg) scaleX(0.95);
	}

	.card_triple_back {
		-moz-transform: translateX(-60%) translateY(-26%) skewY(-6deg) scaleX(0.85);
		-webkit-transform: translateX(-60%) translateY(-26%) skewY(-6deg) scaleX(0.85);
		-o-transform: translateX(-60%) translateY(-26%) skewY(-6deg) scaleX(0.85);
		-ms-transform: translateX(-60%) translateY(-26%) skewY(-6deg) scaleX(0.85);
		transform: translateX(-60%) translateY(-26%) skewY(-6deg) scaleX(0.85);
	}
}

.mockupstyle_centered {
	.card_triple_front,
	.card_triple_inside,
	.card_triple_back, {
		bottom:50%;
		left:50%;
	}
	.card_triple_front {
		-moz-transform: translateX(-50%) translateY(50%);
		-webkit-transform: translateX(-50%) translateY(50%);
		-o-transform: translateX(-50%) translateY(50%);
		-ms-transform: translateX(-50%) translateY(50%);
		transform: translateX(-50%) translateY(50%);
	}

	.card_triple_inside {
		-moz-transform: translateX(-50%) translateY(50%) skewY(4deg) scaleX(0.95);
		-webkit-transform: translateX(-50%) translateY(50%) skewY(4deg) scaleX(0.95);
		-o-transform: translateX(-50%) translateY(50%) skewY(4deg) scaleX(0.95);
		-ms-transform: translateX(-50%) translateY(50%) skewY(4deg) scaleX(0.95);
		transform: translateX(-50%) translateY(50%) skewY(4deg) scaleX(0.95);
	}

	.card_triple_back {
		-moz-transform: translateX(-60%) translateY(34%) skewY(-6deg) scaleX(0.85);
		-webkit-transform: translateX(-60%) translateY(34%) skewY(-6deg) scaleX(0.85);
		-o-transform: translateX(-60%) translateY(34%) skewY(-6deg) scaleX(0.85);
		-ms-transform: translateX(-60%) translateY(34%) skewY(-6deg) scaleX(0.85);
		transform: translateX(-60%) translateY(34%) skewY(-6deg) scaleX(0.85);
	}
}

// card triple square

.card_triple_back.orientation_square,
.card_triple_inside.orientation_square,
.card_triple_front.orientation_square {
	width: 65%;
}

// ratio overwrites square

.card_triple_back.orientation_square.square-ratio-5,
.card_triple_inside.orientation_square.square-ratio-5,
.card_triple_front.orientation_square.square-ratio-5 {
	width: 80%;
}
.card_triple_back.orientation_square.square-ratio-10,
.card_triple_inside.orientation_square.square-ratio-10,
.card_triple_front.orientation_square.square-ratio-10 {
	width: 70%;
}
.card_triple_back.orientation_square.square-ratio-15,
.card_triple_inside.orientation_square.square-ratio-15,
.card_triple_front.orientation_square.square-ratio-15 {
	width: 57%;
}
.card_triple_back.orientation_square.square-ratio-20,
.card_triple_inside.orientation_square.square-ratio-20,
.card_triple_front.orientation_square.square-ratio-20 {
	width: 52%;
}

@media (max-width:767px){

}

/* -------------- quadruple cards ------------------ */

.card_quadruple_front,
.card_quadruple_inside,
.card_quadruple_back,
.card_quadruple_last {
	display: block;
	position: absolute;
}

.card_quadruple_front {
	-moz-transform-origin: top right;
	-webkit-transform-origin: top right;
	-o-transform-origin: top right;
	-ms-transform-origin: top right;
	transform-origin: top right;
}

.card_quadruple_inside {
	-moz-transform-origin: top right;
	-webkit-transform-origin: top right;
	-o-transform-origin: top right;
	-ms-transform-origin: top right;
	transform-origin: top right;
}

.card_quadruple_back {
	-moz-transform-origin: top right;
	-webkit-transform-origin: top right;
	-o-transform-origin: top right;
	-ms-transform-origin: top right;
	transform-origin: top right;
}

.card_quadruple_last {
	-moz-transform-origin: top right;
	-webkit-transform-origin: top right;
	-o-transform-origin: top right;
	-ms-transform-origin: top right;
	transform-origin: top right;
}

.card_quadruple_last img,
.card_quadruple_back img,
.card_quadruple_inside img,
.card_quadruple_front img {
	display: block;
	position: relative;
	margin: 0 auto;
}

.mockupstyle_default {
	.card_quadruple_front,
	.card_quadruple_inside,
	.card_quadruple_back,
	.card_quadruple_last {
		bottom:-1vw;
		left:50%;
	}
	.card_quadruple_front {
		-moz-transform: translateX(-50%) translateY(-10%);
		-webkit-transform: translateX(-50%) translateY(-10%);
		-o-transform: translateX(-50%) translateY(-10%);
		-ms-transform: translateX(-50%) translateY(-10%);
		transform: translateX(-50%) translateY(-10%);

	}

	.card_quadruple_inside {
		-moz-transform: translateX(-50%) translateY(-10%) skewY(4deg) scaleX(0.95);
		-webkit-transform: translateX(-50%) translateY(-10%) skewY(4deg) scaleX(0.95);
		-o-transform: translateX(-50%) translateY(-10%) skewY(4deg) scaleX(0.95);
		-ms-transform: translateX(-50%) translateY(-10%) skewY(4deg) scaleX(0.95);
		transform: translateX(-50%) translateY(-10%) skewY(4deg) scaleX(0.95);
	}

	.card_quadruple_back {
		-moz-transform: translateX(-60%) translateY(-26%) skewY(-6deg) scaleX(0.85);
		-webkit-transform: translateX(-60%) translateY(-26%) skewY(-6deg) scaleX(0.85);
		-o-transform: translateX(-60%) translateY(-26%) skewY(-6deg) scaleX(0.85);
		-ms-transform: translateX(-60%) translateY(-26%) skewY(-6deg) scaleX(0.85);
		transform: translateX(-60%) translateY(-26%) skewY(-6deg) scaleX(0.85);
	}

	.card_quadruple_last {
		-moz-transform: translateX(-60%) translateY(-26%) skewY(6deg) scaleX(0.75);
		-webkit-transform: translateX(-60%) translateY(-26%) skewY(6deg) scaleX(0.75);
		-o-transform: translateX(-60%) translateY(-26%) skewY(6deg) scaleX(0.75);
		-ms-transform: translateX(-60%) translateY(-26%) skewY(6deg) scaleX(0.75);
		transform: translateX(-60%) translateY(-26%) skewY(6deg) scaleX(0.75);
	}
}

.mockupstyle_centered {
	.card_quadruple_front,
	.card_quadruple_inside,
	.card_quadruple_back,
	.card_quadruple_last {
		bottom:50%;
		left:50%;
	}
	.card_quadruple_front {
		-moz-transform: translateX(-50%) translateY(50%);
		-webkit-transform: translateX(-50%) translateY(50%);
		-o-transform: translateX(-50%) translateY(50%);
		-ms-transform: translateX(-50%) translateY(50%);
		transform: translateX(-50%) translateY(50%);

	}

	.card_quadruple_inside {
		-moz-transform: translateX(-50%) translateY(50%) skewY(4deg) scaleX(0.95);
		-webkit-transform: translateX(-50%) translateY(50%) skewY(4deg) scaleX(0.95);
		-o-transform: translateX(-50%) translateY(50%) skewY(4deg) scaleX(0.95);
		-ms-transform: translateX(-50%) translateY(50%) skewY(4deg) scaleX(0.95);
		transform: translateX(-50%) translateY(50%) skewY(4deg) scaleX(0.95);
	}

	.card_quadruple_back {
		-moz-transform: translateX(-60%) translateY(34%) skewY(-6deg) scaleX(0.85);
		-webkit-transform: translateX(-60%) translateY(34%) skewY(-6deg) scaleX(0.85);
		-o-transform: translateX(-60%) translateY(34%) skewY(-6deg) scaleX(0.85);
		-ms-transform: translateX(-60%) translateY(34%) skewY(-6deg) scaleX(0.85);
		transform: translateX(-60%) translateY(34%) skewY(-6deg) scaleX(0.85);
	}

	.card_quadruple_last {
		-moz-transform: translateX(-60%) translateY(34%) skewY(6deg) scaleX(0.75);
		-webkit-transform: translateX(-60%) translateY(34%) skewY(6deg) scaleX(0.75);
		-o-transform: translateX(-60%) translateY(34%) skewY(6deg) scaleX(0.75);
		-ms-transform: translateX(-60%) translateY(34%) skewY(6deg) scaleX(0.75);
		transform: translateX(-60%) translateY(34%) skewY(6deg) scaleX(0.75);
	}
}

// card quadruple square

.card_quadruple_last.orientation_square,
.card_quadruple_back.orientation_square,
.card_quadruple_inside.orientation_square,
.card_quadruple_front.orientation_square {
	width: 45%;
}

// ratio overwrites square

.card_quadruple_last.orientation_square.square-ratio-5,
.card_quadruple_back.orientation_square.square-ratio-5,
.card_quadruple_inside.orientation_square.square-ratio-5,
.card_quadruple_front.orientation_square.square-ratio-5 {
	width: 80%;
}
.card_quadruple_last.orientation_square.square-ratio-10,
.card_quadruple_back.orientation_square.square-ratio-10,
.card_quadruple_inside.orientation_square.square-ratio-10,
.card_quadruple_front.orientation_square.square-ratio-10 {
	width: 70%;
}
.card_quadruple_last.orientation_square.square-ratio-15,
.card_quadruple_back.orientation_square.square-ratio-15,
.card_quadruple_inside.orientation_square.square-ratio-15,
.card_quadruple_front.orientation_square.square-ratio-15 {
	width: 57%;
}
.card_quadruple_last.orientation_square.square-ratio-20,
.card_quadruple_back.orientation_square.square-ratio-20,
.card_quadruple_inside.orientation_square.square-ratio-20,
.card_quadruple_front.orientation_square.square-ratio-20 {
	width: 52%;
}

@media (max-width:767px){

}

/* -------------- theater cards ------------------ */

.card_theater_back,
.card_theater_left,
.card_theater_left_back,
.card_theater_right,
.card_theater_right_back {
	display: block;
	position: absolute;
}

.card_theater_back img {
	display: block;
	position: relative;
	margin: 0 auto;
	width: 100%;

}

.card_theater_left img,
.card_theater_left_back img,
.card_theater_right img,
.card_theater_right_back img {
	display: block;
	position: relative;
	margin: 0 auto;
	height: 100%;
	
}

.card_theater_left,
.card_theater_right {
	height: 100%;
	z-index: 100;
}

.card_theater_left_back,
.card_theater_right_back {
	height: 100%;
	z-index: -1;
}

.card_theater_left,
.card_theater_left_back {
	-moz-transform-origin: top left;
	-webkit-transform-origin: top left;
	-o-transform-origin: top left;
	-ms-transform-origin: top left;
	transform-origin: top left;
}

.card_theater_right,
.card_theater_right_back {
	-moz-transform-origin: top right;
	-webkit-transform-origin: top right;
	-o-transform-origin: top right;
	-ms-transform-origin: top right;
	transform-origin: top right;
}

.mockupstyle_default {
	.card_theater_back,
	.card_theater_left,
	.card_theater_left_back,
	.card_theater_right,
	.card_theater_right_back {
		bottom:0;
		left:50%;
		-moz-transform: translateX(-50%);
		-webkit-transform: translateX(-50%);
		-o-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);
	}
	.card_theater_left {
		-moz-transform: translateX(-100%) skewY(11deg) scaleX(0.85);
		-webkit-transform: translateX(-100%) skewY(11deg) scaleX(0.85);
		-o-transform: translateX(-100%) skewY(11deg) scaleX(0.85);
		-ms-transform: translateX(-100%) skewY(11deg) scaleX(0.85);
		transform: translateX(-100%) skewY(11deg) scaleX(0.85);
	}

	.card_theater_right {
		-moz-transform: translateX(0%) skewY(-11deg) scaleX(0.85);
		-webkit-transform: translateX(0%) skewY(-11deg) scaleX(0.85);
		-o-transform: translateX(0%) skewY(-11deg) scaleX(0.85);
		-ms-transform: translateX(0%) skewY(-11deg) scaleX(0.85);
		transform: translateX(0%) skewY(-11deg) scaleX(0.85);
	}

	.card_theater_left_back {
		-moz-transform: translateX(0%) skewY(-11deg) scaleX(0.85);
		-webkit-transform: translateX(0%) skewY(-11deg) scaleX(0.85);
		-o-transform: translateX(0%) skewY(-11deg) scaleX(0.85);
		-ms-transform: translateX(0%) skewY(-11deg) scaleX(0.85);
		transform: translateX(0%) skewY(-11deg) scaleX(0.85);
	}

	.card_theater_right_back {
		-moz-transform: translateX(100%) skewY(11deg) scaleX(0.85);
		-webkit-transform: translateX(100%) skewY(11deg) scaleX(0.85);
		-o-transform: translateX(100%) skewY(11deg) scaleX(0.85);
		-ms-transform: translateX(100%) skewY(11deg) scaleX(0.85);
		transform: translateX(100%) skewY(11deg) scaleX(0.85);
	}
}

.mockupstyle_centered {
	.card_theater_back,
	.card_theater_left,
	.card_theater_right {
		bottom:50%;
		left:50%;
		-moz-transform: translateX(-50%) translateY(50%);
		-webkit-transform: translateX(-50%) translateY(50%);
		-o-transform: translateX(-50%) translateY(50%);
		-ms-transform: translateX(-50%) translateY(50%);
		transform: translateX(-50%) translateY(50%);
	}
	.card_theater_left {
		-moz-transform: translateX(-100%) translateY(50%) skewY(11deg) scaleX(0.85);
		-webkit-transform: translateX(-100%) translateY(50%) skewY(11deg) scaleX(0.85);
		-o-transform: translateX(-100%) translateY(50%) skewY(11deg) scaleX(0.85);
		-ms-transform: translateX(-100%) translateY(50%) skewY(11deg) scaleX(0.85);
		transform: translateX(-100%) translateY(50%) skewY(11deg) scaleX(0.85);
	}

	.card_theater_right {
		-moz-transform: translateX(0%) translateY(50%) skewY(-11deg) scaleX(0.85);
		-webkit-transform: translateX(0%) translateY(50%) skewY(-11deg) scaleX(0.85);
		-o-transform: translateX(0%) translateY(50%) skewY(-11deg) scaleX(0.85);
		-ms-transform: translateX(0%) translateY(50%) skewY(-11deg) scaleX(0.85);
		transform: translateX(0%) translateY(50%) skewY(-11deg) scaleX(0.85);
	}

	.card_theater_left_back {
		-moz-transform: translateX(0%) translateY(-100%) skewY(-11deg) scaleX(0.85);
		-webkit-transform: translateX(0%) translateY(-100%) skewY(-11deg) scaleX(0.85);
		-o-transform: translateX(0%) translateY(-100%) skewY(-11deg) scaleX(0.85);
		-ms-transform: translateX(0%) translateY(-100%) skewY(-11deg) scaleX(0.85);
		transform: translateX(0%) translateY(-100%) skewY(-11deg) scaleX(0.85);
	}

	.card_theater_right_back {
		-moz-transform: translateX(100%) translateY(-100%) skewY(11deg) scaleX(0.85);
		-webkit-transform: translateX(100%) translateY(-100%) skewY(11deg) scaleX(0.85);
		-o-transform: translateX(100%) translateY(-100%) skewY(11deg) scaleX(0.85);
		-ms-transform: translateX(100%) translateY(-100%) skewY(11deg) scaleX(0.85);
		transform: translateX(100%) translateY(-100%) skewY(11deg) scaleX(0.85);
	}
}

// card theater square

.card_theater_back.orientation_square {
	width: 80%;
}

// card theater portrait


.card_theater_back.orientation_portrait {
	width: 45%;
}

// card theater landscape

.card_theater_back.orientation_landscape {
	width: 85%;
}

// ratio overwrites square

.card_theater_back.orientation_square.square-ratio-5 {
	width: 80%;
}
.card_theater_back.orientation_square.square-ratio-10 {
	width: 80%;
}
.card_theater_back.orientation_square.square-ratio-15 {
	width: 57%;
}
.card_theater_back.orientation_square.square-ratio-20 {
	width: 52%;
}

// ratio overwrites portrait

.card_theater_back.orientation_portrait.portrait-ratio-5 {
	width: 74%;
}
.card_theater_back.orientation_portrait.portrait-ratio-10 {
	width: 54%;
}
.card_theater_back.orientation_portrait.portrait-ratio-15 {
	width: 45%;
}
.card_theater_back.orientation_portrait.portrait-ratio-20 {
	width: 20%;
}

// ratio overwrites landscape

.card_theater_back.orientation_landscape.landscape-ratio-5 {
	width: 90%;
}
.card_theater_back.orientation_landscape.landscape-ratio-10 {
	width: 71%;
}
.card_theater_back.orientation_landscape.landscape-ratio-15 {
	width: 67%;
}
.card_theater_back.orientation_landscape.landscape-ratio-20 {
	width: 57%;
}

// ---- product detail page spreads ---- //

.card_spread {
	width: 85%;
	max-width: 510px;
	display: block;
	position: relative;
	left: 50%;
    top: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
	-webkit-transform: translateX(-50%) translateY(-50%);
	-o-transform: translateX(-50%) translateY(-50%);
	-ms-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);

	.card-spread-imageholder {
		display: flex;
		flex-direction: row;
		img {
			height: auto;
		}
	}

	&:after,
	&:before {
		display: block;
		position: absolute;
		top:0;
		width: 1px;
		height: 100%;
		background-color:rgba(0,0,0,0.1);
	}
}

.card_spread.portrait-semitall {
	width: 62%;
	max-width: 420px;
}

// spread tweeluik
.card_spread_tweeluik {
	&:after {
		content:'';
		left:calc(50% - 1px);
	}

	.card-spread-imageholder img {
		width: 50%;
	}
}

// spread tweeluik bovenvouw
.card_spread_tweeluik_bovenvouw {
	display: inline-block;
    width: 46%;
    height: 80%;
    position: absolute;
    left: 50%;
    top: 50%;
    -moz-transform: translateY(-50%) translateX(-50%);
	-webkit-transform: translateY(-50%) translateX(-50%);
	-o-transform: translateY(-50%) translateX(-50%);
	-ms-transform: translateY(-50%) translateX(-50%);
	transform: translateY(-50%) translateX(-50%);

	.card-spread-imageholder {
		display: block;
		width: 100%;
		height: 100%;

		img {
			display: block;
		    position: relative;
		    width: auto;
		    height: 50%;
		}

		&:before {
			content: '';
			display: block;
			position: absolute;
			left:0;
			top:0;
		    height: 100%;
		    width: 100%;
		    z-index: 2;
		    background-color: #000000;

			/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.1+100 */
			/* IE9 SVG, needs conditional override of 'filter' to 'none' */
			background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMTAwJSI+CiAgICA8c3RvcCBvZmZzZXQ9IjAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwLjEiLz4KICA8L2xpbmVhckdyYWRpZW50PgogIDxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9InVybCgjZ3JhZC11Y2dnLWdlbmVyYXRlZCkiIC8+Cjwvc3ZnPg==);
			background: -moz-linear-gradient(-45deg,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.1) 100%); /* FF3.6-15 */
			background: -webkit-gradient(linear, left top, right bottom, color-stop(0%,rgba(0,0,0,0)), color-stop(100%,rgba(0,0,0,0.1))); /* Chrome4-9,Safari4-5 */
			background: -webkit-linear-gradient(-45deg,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.1) 100%); /* Chrome10-25,Safari5.1-6 */
			background: -o-linear-gradient(-45deg,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.1) 100%); /* Opera 11.10-11.50 */
			background: -ms-linear-gradient(-45deg,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.1) 100%); /* IE10 preview */
			background: linear-gradient(135deg,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#1a000000',GradientType=1 ); /* IE6-8 fallback on horizontal gradient */

			background-blend-mode: multiply;
		}

		&:after {
			content:'';
			display: block;
			position: absolute;
			top:calc(50% - 1px);
			left:0;
			width: 100%;
			height: 1px;
			background-color:rgba(0,0,0,0.1);
		}
	}
}
// .card_spread_tweeluik_bovenvouw {
// 	.card-spread-imageholder {
// 		flex-direction: column !important;
// 	}
// 	&:after {
// 		content:'';
// 		width:100%;
// 		height: 1px;
// 		left:0;
// 		top:calc(50% - 1px);
// 	}

// 	.card-spread-imageholder img {
// 		width: 50%;
// 	}
// }

// spread drieluik
.card_spread_drieluik {
	&:after {
		content:'';
		left:calc(33.33% - 1px);
	}

	&:before{
		content:'';
		left:calc(66.66% - 1px);
	}

	.card-spread-imageholder img {
		width: 33.33%;
	}
}

// spread vierluik
.card_spread_vierluik {
	&:after {
		content:'';
		left:calc(25% - 1px);
	}

	&:before{
		content:'';
		left:calc(50% - 1px);
	}

	.card-spread-imageholder {
		&:before {
			content:'';
			left:calc(75% - 1px);
			display: block;
			position: absolute;
			top:0;
			width: 1px;
			height: 100%;
			background-color:rgba(0,0,0,0.1);
		}
		img {
			width: 25%;
		}
	}
}

// spread theater
.card_spread_theater {
	display: flex;
	flex-direction: row;
	background-color: #c2c2c2;
	img {
		display: block;
		position: relative;		
		height: auto;
	}
	&:after {
		content: "";
		clear: both;
		display: table;
	}
	img.theater-left {
		border-right: 1px solid transparent;
	}
	img.theater-middle {
		border-right: 1px solid transparent;
	}
	img.theater-right {
		
	}
}

// spread theater oriantation square

.card_spread_theater.orientation_square {
	img.theater-left {
		width: 25%;
	}
	img.theater-middle {
		width: 50%;
	}
	img.theater-right {
		width: 25%;
	}
}

// spread theater oriantation portrait

.card_spread_theater.orientation_portrait {
	img.theater-left {
		width: 25%;
	}
	img.theater-middle {
		width: 50%;
	}
	img.theater-right {
		width: 25%;
	}
}

// spread theater oriantation landscape

.card_spread_theater.orientation_portrait {
	img.theater-left {
		width: 25%;
	}
	img.theater-middle {
		width: 50%;
	}
	img.theater-right {
		width: 25%;
	}
}

// posters can be displayd a bit larger

.category-product-image .eenluik.posters.f-210-300 .card_single_front.orientation_portrait { /* 21x30 */
    width: 56%;
}
.category-product-image .eenluik.posters.f-300-400 .card_single_front.orientation_portrait { /* 30x40 */
    width: 64%;
}

.category-product-image .eenluik.posters.f-400-500 .card_single_front.orientation_portrait { /* 40x50 */
    width: 72%;
}

.category-product-image .eenluik.posters.f-250-350 .card_single_front.orientation_portrait { /* 50x70 */
    width: 72%;
}