// VARIABLES -----------------------------------------------------------------------------------------------------------

$typeahead-font-size: 0.8rem !default;
$typeahead-line-height: 1.55 !default;
$typeahead-font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif !default;
$typeahead-primary-color: #999 !default;
$typeahead-cancel-color: #d0021b !default;
$typeahead-radius: 0px !default;
$typeahead-shadow: false !default; // true / false
$typeahead-dropdown-spacing: 0px !default;
// $typeahead-outline-color: $typeahead-primary-color !default;
$typeahead-loading-size: 1.35rem !default;
$typeahead-width:80%;

$typeahead-input-background:#F4F4F4;
$typeahead-input-border-color:#F4F4F4;
$typeahead-input-outline-color:#F4F4F4;

$typeahead-padding-y: 0.5rem !default;
$typeahead-padding-x: 0rem !default;

$typeahead-color: #555 !default;
$typeahead-border-color: #ccc !default;

$typeahead-group-color: darken($typeahead-primary-color, 30%) !default;
$typeahead-group-background: lighten($typeahead-primary-color, 30%) !default;
$typeahead-group-border-color: lighten($typeahead-primary-color, 20%) !default;

$typeahead-item-color: #333 !default;
$typeahead-item-background: #fff !default;
$typeahead-item-hover-background: #f5f5f5 !default;
$typeahead-item-disabled-color: #bababa !default;
$typeahead-item-disabled-background: #fafafa !default;

$typeahead-label-color: #3a99fc !default;
$typeahead-label-font-size: calc(#{$typeahead-font-size} * 0.875) !default;
$typeahead-label-border-color: #c2e0ff !default;
$typeahead-label-background: lighten($typeahead-primary-color, 30%) !default;

// bootstrap 5, remove cross from search input -------------------------------------------------------------------------

/* clears the 'X' from Internet Explorer */
#site-search-form, #site-search-form-mobile {
    input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
    input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }

    /* clears the 'X' from Chrome */
    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration { display: none; }
}

// TYPEAHEAD -----------------------------------------------------------------------------------------------------------

#site-search-form {
    display: block;
    width: $typeahead-width;
}

#site-search-form-mobile {
    display: block;
    width: 100%;
}

.typeahead__ {
    &container {
        position: relative;
        font-size: $typeahead-font-size;
        font-family: inherit;
    }

    &container * {
        box-sizing: border-box;
        outline: 0;
    }

    &query {
        position: relative;
        z-index: 1;
        width: 100%;
    }

    &filter {
        position: relative;
        button {
            min-width: 100%;
            white-space: nowrap;
            &:after {
                display: inline-block;
                margin-left: 4px;
                width: 0;
                height: 0;
                vertical-align: -2px;
                content: "";
                border: 4px solid;
                border-right-color: transparent;
                border-bottom-color: transparent;
                border-left-color: transparent;
            }
        }
    }

    &field {
        display: flex;
        position: relative;
        width: 100%;
    }

    &button button {
        border-top-right-radius: $typeahead-radius;
        border-bottom-right-radius: $typeahead-radius;
    }

    &field {
        // #193 If applied to input, it overrides the placeholder color on IE10/11
        color: $typeahead-color;
        input,
        textarea,
        [contenteditable],
        .typeahead__hint {
            display: block;
            width: 100%;
            line-height: $typeahead-line-height;
            min-height: calc(#{$typeahead-padding-y} * 2 + 1.25rem + 2px);
            height:38px;
            padding: $typeahead-padding-y $typeahead-padding-x;
            background: $typeahead-input-background;
            // border: 1px solid $typeahead-input-border-color;
            border: none;
            border-radius: $typeahead-radius 0 0 $typeahead-radius;
            appearance: none;
            box-sizing: border-box;
            @if ($typeahead-shadow) {
                box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
            }

            &:focus,
            &:active {
                border-color: $typeahead-input-outline-color;
            }

            //border-color: #007eff;
            //box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1);
        }
    }

    &container.hint .typeahead__field {
        input,
        textarea,
        [contenteditable] {
            background: transparent;
        }
    }

    &container.hint .typeahead__query > :last-child,
    &hint {
        background: $typeahead-input-background;
    }

    &container button {
        display: inline-block;
        margin-bottom: 0;
        text-align: center;
        touch-action: manipulation;
        cursor: pointer;
        background-color: $typeahead-input-background;
        border: 1px solid $typeahead-input-border-color;
        // border:none;
        height:38px;
        line-height: $typeahead-line-height;
        padding: $typeahead-padding-y $typeahead-padding-x;
        user-select: none;
        color: $typeahead-color;
        @if ($typeahead-shadow) {
            box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.05);
        }

        &:hover,
        &:focus {
            color: darken($typeahead-color, 10%);
            background-color: $typeahead-item-hover-background;
            border-color: darken($typeahead-border-color, 10%);
        }

        &:active,
        &.active {
            background-image: none;
            @if ($typeahead-shadow) {
                box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
            }
        }

        &:focus,
        &:active {
            border-color: $typeahead-input-outline-color;
        }
    }

    &container {
        input.disabled,
        input[disabled],
        button.disabled,
        button[disabled] {
            cursor: not-allowed;
            pointer-events: none;
            opacity: 0.65;
            box-shadow: none;
            background-color: $typeahead-input-background;
            border-color: $typeahead-input-border-color;
        }

        //&.multiselect,
        //&.loading,
        //&.cancel {
        .typeahead__field {
            input,
            textarea,
            [contenteditable],
            .typeahead__hint,
            .typeahead__label-container {
                padding-right: 32px;
                padding-left:10px;
            }
        }
        //}
    }

    &filter,
    &button {
        z-index: 2;
        button {
            margin-left: -1px;
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
        }

        &:hover,
        &:active,
        &:focus {
            z-index: 1001;
            button {
                &:focus,
                &:active {
                    z-index: 1001;
                }
            }
        }
    }

    &filter + &button {
        button {
            margin-left: -2px;
        }
    }

    &container.filter .typeahead__filter {
        z-index: 1001;
    }

    &list,
    &dropdown {
        position: absolute;
        //top: 100%;
        left: -1px;
        z-index: 1000;
        width: calc(100% + 1px) !important;
        min-width: 160px;
        padding: $typeahead-dropdown-spacing 0;
        margin: 0;
        list-style: none;
        text-align: left;
        background-color: #fff;
        // border: 1px solid $typeahead-border-color;
        border:none;
        border-radius: $typeahead-radius;
        background-clip: padding-box;
        @if ($typeahead-shadow) {
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
        }
    }

    &result.detached .typeahead__list {
        position: relative;
        z-index: 1041;
        top: initial;
        left: initial;
    }

    &dropdown {
        right: 0;
        left: initial;
        z-index: 1001;
    }

    &list > li {
        position: relative;
        // border-top: solid 1px $typeahead-border-color;
        border:none;
        &:first-child {
            border-top: none;
        }
    }

    &list .typeahead__item,
    &dropdown .typeahead__dropdown-item {
        &[disabled] {
            > a {
                cursor: not-allowed;
                color: $typeahead-item-disabled-color;
                background-color: $typeahead-item-disabled-background;
            }
        }

        > a {
            display: block;
            padding: $typeahead-padding-y $typeahead-padding-x;
            clear: both;
            color: $typeahead-item-color;
            text-decoration: none;
        }

        &:not([disabled]) {
            > a:hover,
            > a:focus,
            &.active > a {
                background-color: $typeahead-item-hover-background;
                color: darken($typeahead-color, 10%);
            }
        }
    }

    &list.empty {
        > li {
            padding: $typeahead-padding-y $typeahead-padding-x;
            color: $typeahead-item-color;
        }
    }

    &list > .typeahead__group {
        border-color: $typeahead-group-border-color;
        font-weight: bold;
        text-transform: uppercase;

        &:first-child {
            @if ($typeahead-dropdown-spacing > 0) {
                // border-top: solid 1px $typeahead-group-border-color;
                border:none;
            }
        }
        > a,
        > a:hover,
        > a:focus,
        &.active > a {
            cursor: default;
            color: $typeahead-group-color;
            background: $typeahead-group-background;
            display: block;
            padding: $typeahead-padding-y $typeahead-padding-x;
            clear: both;
            text-decoration: none;
        }
    }

    &list > {
        li.typeahead__group + li.typeahead__item {
            border-color: $typeahead-group-border-color;
        }
    }

    &container {
        &.result .typeahead__list,
        &.filter .typeahead__dropdown,
        &.hint .typeahead__hint,
        &.backdrop + .typeahead__backdrop {
            display: block !important;
        }
        .typeahead__list,
        .typeahead__dropdown,
        .typeahead__hint,
        + .typeahead__backdrop {
            display: none !important;
        }
    }

    &dropdown li:last-child {
        margin-top: $typeahead-dropdown-spacing;
        padding-top: $typeahead-dropdown-spacing;
        border-top: solid 1px $typeahead-border-color;
    }

    &cancel-button {
        user-select: none;
        position: absolute;
        right: 0;
        cursor: pointer;
        line-height: 0.8;
        font-size: 1.5rem;
        height:100%;
        padding: $typeahead-padding-y $typeahead-padding-x;
        visibility: hidden;
        .typeahead__label & {
            visibility: visible;
            right: 4px;
        }
    }

    &container.cancel:not(.loading),
    &label {
        .typeahead__cancel-button {
            visibility: visible;
            &:hover {
                color: $typeahead-cancel-color;
            }
        }
    }

    &search-icon {
        display: block;
        padding: 0 1.25rem;
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-color: transparent;
        background-size: contain;
        background-position: center center;
    }

    &container.loading .typeahead__query {
        &:before,
        &:after {
            transition: all 0s linear, opacity 0.2s ease;
            position: absolute;
            z-index: 3;
            content: "";
            top: 50%;
            right: 0.55em;
            margin-top: calc(($typeahead-loading-size / 2)*-1);
            width: $typeahead-loading-size;
            height: $typeahead-loading-size;
            box-sizing: border-box;
            border-radius: 500rem;
            border-style: solid;
            border-width: 0.1em;
        }

        &:before {
            border-color: rgba(0, 0, 0, 0.35);
        }

        &:after {
            animation: button-spin 0.6s linear;
            animation-iteration-count: infinite;
            border-color: #fff transparent transparent;
            box-shadow: 0 0 0 1px transparent;
        }

        @keyframes button-spin {
            from {
                transform: rotate(0deg);
            }
            to {
                transform: rotate(360deg);
            }
        }
    }

    &label-container {
        list-style: none;
        position: absolute;
        padding-top: calc(#{$typeahead-font-size} * 0.375);
        padding-left: 6px;
        width: 100%;
        flex-wrap: wrap;
        display: flex;
    }

    &label {
        display: flex;
        font-size: $typeahead-label-font-size;
        position: relative;
        background: $typeahead-label-background;
        // border: solid 1px $typeahead-label-border-color;
        border:none;
        padding-left: 4px;
        border-radius: $typeahead-radius;
        margin-right: 4px;
        margin-bottom: calc(#{$typeahead-font-size} * 0.375);

        > * {
            align-self: center;
        }

        .typeahead__cancel-button {
            line-height: normal;
            font-size: 1rem;
            height:100%;
            // height: auto;
            position: static;
            padding-top: calc(#{$typeahead-font-size} * 0.25 - 1px);
            padding-bottom: calc(#{$typeahead-font-size} * 0.25 + 1px);
            padding-left: 6px;
            padding-right: 6px;
            margin-left: 4px;
            font-size: $typeahead-label-font-size;
            border-left: solid 1px $typeahead-label-border-color;
            &:hover {
                background-color: darken($typeahead-label-background, 5%);
            }
        }
    }
}
