.block-images {
	a {
		display: block;
		width: 100%;
		position: relative;
		text-decoration: none;
		color: inherit;
	}

	img {
		width: 100%;
	}
}

.block-images-title_bottom {
	.block-images-title  {
		display: block;
		position: absolute;
		left: 0;
		width: 100%;
		padding: 0.6em;
		text-align: center;
		z-index: 5;
		bottom: 0;
		background-color: rgba(255,255,255,.8);
	}
}

.block-images-title_overlay {
	.block-images-title  {
		display: block;
		position:absolute;
		width: 100%;
		bottom:0;
		z-index: 5;
		padding-bottom:2em;
		padding-top:2em;
		padding-left:2em;
		padding-right:2em;
		text-align: center;
	}

	.btn {
		padding: 0.5rem 1rem;
	}
}

.block-images-title_caption {
	.block-images-title {
		display: block;
		position: relative;
		padding-top:0.7em;
		padding-bottom:0.6em;
		border-bottom:1px currentColor solid;
		padding-right:2em;
	}

	.drn-icon-menu-arrow {
		position: absolute;
		right:-1em;
		-moz-transform: scale(0.7) rotate(-90deg);
		-webkit-transform: scale(0.7) rotate(-90deg);
		-o-transform: scale(0.7) rotate(-90deg);
		-ms-transform: scale(0.7) rotate(-90deg);
		transform: scale(0.7) rotate(-90deg);
	}
}
