.block-content {
	display: block;
	position: relative;
	width: 100%;
	height: 100%;
}

// fullwidth grid, text element 6,6

.block-fullwidth-text-6 {
	max-width: 636px;
}

.block-fullwidth-text-6-left {
	margin-left: auto;
}

@media (max-width: 1399px){
	.block-fullwidth-text-6 {
		max-width: 546px;
	}
}

@media (max-width: 1199px){
	.block-fullwidth-text-6 {
		max-width: 456px;
	}
}

@media (max-width: 991px){
	.block-fullwidth-text-6 {
		max-width: 336px;
	}
}

@media (max-width: 767px){
	.block-fullwidth-text-6 {
		max-width: 540px;
		margin: 0 auto;
	}
}

@media (max-width: 575px){
	.block-fullwidth-text-6 {
		max-width: 516px;
	}
}

// fullwidth grid, text element 12

.block-fullwidth-text-12 {
	width: 100%;
	//margin: 0 auto;
	    // margin-right: calc(var(--bs-gutter-x) * -.5);
    	// margin-left: calc(var(--bs-gutter-x) * -.5);
}

/*
@media (max-width: 1399px){
	.block-fullwidth-text-12 {
		max-width: 1140px;
	}
}

@media (max-width: 1199px){
	.block-fullwidth-text-12 {
		max-width: 960px;
	}
}

@media (max-width: 991px){
	.block-fullwidth-text-12 {
		max-width: 720px;
	}
}

@media (max-width: 767px){
	.block-fullwidth-text-12 {
		max-width: 540px;
	}
}

@media (max-width: 575px){
	.block-fullwidth-text-12 {
		//max-width: 540px;
	}
}
*/