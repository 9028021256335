.accordion {
	display: block;
	position: relative;
	width: 100%;
	margin-bottom: 1em;

	.accordion-title {
		display: block;
		position:relative;
		width: 100%;
		margin-top:0.5rem;
		margin-bottom:0.5rem;
		padding-top:0.5rem;
		padding-bottom:0.5rem;
		padding-right:2rem;
		border-bottom:var(--primary-01) 1px solid;
		cursor: pointer;

		&:after {
			display: block;
			position: absolute;
			top: 50%;
			right: 0;
			font-size: 1.2rem;
			line-height:0;
			-moz-transform: translateX(-50%);
			-webkit-transform: translateX(-50%);
			-o-transform: translateX(-50%);
			-ms-transform: translateX(-50%);
			transform: translateX(-50%);

			-moz-transition: -webkit-transform 200ms ease-in-out;
			-webkit-transition: -webkit-transform 200ms ease-in-out;
			-o-transition: -webkit-transform 200ms ease-in-out;
		    -ms-transition: -ms-transform 200ms ease-in-out;
		    transition: transform 200ms ease-in-out;
			
			-moz-transform-origin: center;
		    -webkit-transform-origin: center;
			-o-transform-origin: center;
			-ms-transform-origin: center;
		    transform-origin: center;
		}
	}

	.accordion-title-arrow {
		&:after {
			content: '\2303';
			-moz-transform: rotate(0deg);
			-webkit-transform: rotate(0deg);
			-o-transform: rotate(0deg);
			-ms-transform: rotate(0deg);
			transform: rotate(0deg);
		}
	}

	.accordion-title-arrow.closed {
		&:after {
			-moz-transform: rotate(180deg);
			-webkit-transform: rotate(180deg);
			-o-transform: rotate(180deg);
			-ms-transform: rotate(180deg);
			transform: rotate(180deg);
		}
	}

	.accordion-title-plus {
		&:after {
			content: '\2212';
			font-size: 1.6rem;
			-moz-transform: rotate(0deg);
			-webkit-transform: rotate(0deg);
			-o-transform: rotate(0deg);
			-ms-transform: rotate(0deg);
			transform: rotate(0deg);
		}
	}

	.accordion-title-plus.closed {
		&:after {
			content: '\002B';
			-moz-transform: rotate(180deg);
			-webkit-transform: rotate(180deg);
			-o-transform: rotate(180deg);
			-ms-transform: rotate(180deg);
			transform: rotate(180deg);
		}
	}

	.accordion-content-expander {
		display: block;
		position:relative;
		width: 100%;
		overflow: hidden;
		height: auto;
		background-color: #FFFFFF;
	}

	.accordion-content {
		display: block;
		position:relative;
		width: 100%;
	}

}