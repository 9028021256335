@media (max-width: 1399px){
	.hide-xxl {
		display: none !important;
	}
}

@media (max-width: 1199px){
	.hide-xl {
		display: none !important;
	}
}

@media (max-width: 991px){
	.hide-lg {
		display: none !important;
	}
}

@media (max-width: 767px){
	.hide-sm {
		display: none !important;
	}
	.media-desktop {
		display: none !important;
	}
}

@media (max-width: 575px){
	.hide-xs {
		display: none !important;
	}
}



@media (min-width: 1400px){
	.show-xxl {
		display: none !important;
	}
}

@media (min-width: 1200px){
	.show-xl {
		display: none !important;
	}
}

@media (min-width: 992px){
	.show-lg {
		display: none !important;
	}
}

@media (min-width: 768px){
	.show-sm {
		display: none !important;
	}
	.media-mobile {
		display: none !important;
	}
}

@media (min-width: 576px){
	.show-xs {
		display: none !important;
	}
}