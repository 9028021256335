// wishlist popup

#wishlistModal {
	.modal-header {
		border-bottom:none;
	}
	.modal-title {
		text-align: center;
		color: var(--primary-01);
	}
	.modal-title .drn-icon {
		// shift 1 em from left
		padding-left:1em;
	}
}

#wishlistSlick {

	display: block;
	position: relative;
	width: 100%;
	margin-top: 30px;

	.product-prev,
	.product-next {
		display: block;
		position: absolute;
		z-index: 100;
		top:0;
		width: 20px;
		height: 100%;
		background-color: transparent;
		border:none;
		color: var(--primary-01);
	}

	.product-prev {
		left:0;
			-moz-transform: rotate(180deg);
			-webkit-transform: rotate(180deg);
			-o-transform: rotate(180deg);
			-ms-transform: rotate(180deg);
			transform: rotate(180deg);
			transform-origin: center;
	}

	.product-next {
		right:0;
	}

	.drn-icon-button-arrow {
		display: block;
	    position: absolute;
	    left: 50%;
	    top: 50%;
	    -moz-transform: translateX(-50%) translateY(-50%);
	    -webkit-transform: translateX(-50%) translateY(-50%);
	    -o-transform: translateX(-50%) translateY(-50%);
	    -ms-transform: translateX(-50%) translateY(-50%);
	    transform: translateX(-50%) translateY(-50%);
	}
}

.wishlist-item-holder {
	width: 100%;
	padding-bottom: 3em;
	display: block !important;
}

.wishlist-img-container {
	display: block;
	position: relative;
	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
		object-position: center center;
	}
}

.whislist-footer {
	display: block;
	position: relative;
	width: 100%;
	margin-top:1em;
	padding-right:25px;
}

.whislist-footer-like {
	display: block;
	position:absolute;
	top:0;
	right:0;
}