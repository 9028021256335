.stepper-holder {
    margin-bottom: 40px; // dynamisch maken
    margin-top: 40px; // dynamisch maken
}

.stepper-wrapper {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
}

.stepper-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

    a {
        text-decoration: none;
        color:inherit;
    }

    &::before {
        position: absolute;
        content: "";
        border-bottom: 2px solid var(--cartstep-stepsnext-border);
        width: 100%;
        top: 18px;
        left: -50%;
        z-index: 2;
    }

    &::after {
        position: absolute;
        content: "";
        border-bottom: 2px solid var(--cartstep-stepsnext-border);
        width: 100%;
        top: 18px;
        left: 50%;
        z-index: 2;
    }

    .step-name {
        font-size: 12px;
    }

    .step-counter {
        position: relative;
        z-index: 5;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background: var(--cartstep-stepsnext-background);
        border: 2px solid var(--cartstep-stepsnext-border);
        color:var(--cartstep-stepsnext-color);
        margin-bottom: 6px;
    }

    &.completed .step-counter {
        background-color: var(--cartstep-stepsactive-background);
        border-color: var(--cartstep-stepsactive-border);
        color:var(--cartstep-stepsactive-color);
    }

    &.completed::after {
        position: absolute;
        content: "";
        border-bottom: 2px solid var(--cartstep-stepsactive-border);
        width: 100%;
        top: 18px;
        left: 50%;
        z-index: 3;
    }

    &.completed-checked .step-counter {
        
    }

    &.completed-checked .step-counter:after {
        content:'\2713';
        display: block;
        position: absolute;
        z-index: 5;
        background-color: inherit;
        width: 60%;
        text-align: center;
    }

    &:first-child::before {
        content: none;
    }

    &:last-child::after {
        content: none;
    }
}

// mediaqueries

@media (max-width: 1399px){
    
}

@media (max-width: 1199px){
    
}

@media (max-width: 991px){
    #cart-backto-editor {
        position: relative;
        text-align: center;
    }
    .stepper-holder {
        margin-top: 20px;
    }
}

@media (max-width: 767px) {
    .stepper-item  {
        &::after,
        &::before {
            top: 15px;
        }
        &.completed:after {
            top: 15px;
        }
        .step-counter {
            width: 30px;
            height: 30px;
            font-size: 12px;
        }
        .step-name {
            font-size: 10px;
        }
    }
}

@media (max-width: 575px){
    
}