#category-categorylist {
	.row {
		margin-right: calc(-.5 * var(--bs-gutter-x));
    	margin-left: calc(-.5 * var(--bs-gutter-x));
	}
}

.col-category-product {
	display: block;
	position: relative;
}

.category-product-holder {
	display: block;
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.category-product-image {
	display: block;
	position: relative;
	width: 100%;
	height: 0;
	padding-top: 100%;
}

.category-product-header {
	display: block;
	position: absolute;
	z-index: 5;
	text-align: left;
	left:0;
	top:0;
	width: 100%;
	height: auto;

	.category_product_label {
		display: inline-block;
		vertical-align: top;
    	line-height: 1em;
    	margin-right: 2px;
	}
}

.category-product-footer {
	display: block;
	position: relative;
	width: 100%;
	min-height: 2em;
}

.category-product-footer-tools {
	display: block;
    position: absolute;
    top: 0;
    width: auto;
    padding: 0;
}

.category-product-footer-price {
	display: block;
	position: relative;
}

.category-product-footer-left {
	padding-left:8%;
	padding-right:30px;
	text-align: left;
	.category-product-footer-tools {
		right:6%;
	}
}

.category-product-footer-center {
	padding-left:30px;
	padding-right:30px;
	.category-product-footer-tools {
		right:6%;
	}
}

.category-product-footer-right {
	padding-left:30px;
	padding-right:8%;
	text-align: right;
	.category-product-footer-tools {
		left:6%;
	}
}

.category-product-footer-text {
	display: block;
	position: relative;
}

.category-product-footer-title {
	display: block;
	position: relative;
}

.category-product-footer-labels {
	.category_product_label_seperator {
		display: inline;
		&:before {
			content: '|';
			padding-left:0.5em;
			padding-right:0.5em;
		}
		&:first-child:before {
			content: '';
			padding-left:0em;
			padding-right:0em;
		}
	}
}

.category-product-addtowish {
	display: block;
	position: relative;
}

.category-product-addtowish.checked {

} 

.category-product-addtowish.checked:after {

}

// banners

.category-add-holder {
	display: block;
	position: relative;
	width: 100%;
	height: 100%;
	min-height: 25px;
}

// upselling

#upsell-wrapper {
	display: block;
	position: relative;
	width: 100%;
}

.upsell-holder {
	display: block;
	position: relative;
	width: 20%;
	max-width: 277px;
	height: 100%;
	overflow: hidden;
	padding-top:1.5em;
	padding-left:1.5em;
	padding-right:1.5em;
	padding-bottom:2.5em;
	background-color: rgba(250,250,250,0.7);
}

.upsell-holder-slider {
	width: 100%;
	padding-bottom: 3em;
}

.upsell-img-container {
	display: block;
	position: relative;
	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
		object-position: center center;
	}
}

.upsell-type {
	display: block;
    position: relative;
    width: 80%;
    color: currentColor;
    font-size: 1em;
    font-weight: bold;
}

.save-upsell-choice {
	display: block;
	position: relative;
	float:right;
	cursor: pointer;
}

.upsell-footer {
	line-height: 1.4em;
	.category-product-addtowish {
		float:right;
		margin-right: -0.4em;
	}
}

.upsell-footer-title {
	display: block;
    position: relative;
    width: 100%;
    font-size: 0.9em;
}

.upsell-footer-action {
	display: block;
    position: absolute;
    bottom:0;
    width: calc(100% - 3em);
    padding-bottom:1em;
    font-size: 0.9em;

    &:hover,
    &:active, {
    	font-size: 0.9em;
    }

    &:after {
    	content:'\2715';
    	display: block;
    	position: relative;
    	float:right;
    	color:#000000;
    	-moz-transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
		-o-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
    }
}

.upsell-footer-button {
	display: block;
    position: absolute;
    bottom:0;
    width: calc(100% - 2em);
    margin-bottom:1em;
}

// upselling slider

#upselling-complete-background {
	display:none;
	position:fixed;
	width:100%;
	height:100%;
	left:0;
	top:0;
	z-index:1500;
	background-color:rgba(0,0,0,0.6);
	opacity:0;
}

#upselling-complete-holder {
	display:block;
	position:fixed;
	width:100%;
	z-index:2000;
	left:0;
	bottom:-50vh;
	background-color:#FFFFFF;
	padding-top: 1.5em;
	padding-bottom: 1.5em;
	padding-left: 1em;
	padding-right: 1em;
	-webkit-transition: all 300ms ease-in-out;
	-moz-transition: all 300ms ease-in-out;
	-ms-transition: all 300ms ease-in-out;
	-o-transition: all 300ms ease-in-out;
	transition: all 300ms ease-in-out;
}

#upselling-complete-holder.open {
	bottom:0;
}

#upselling-complete-background.open {
	opacity:1;
	display:block;
}

#upselling-complete-slider {

	display: block;
	position: relative;
	width: 100%;;

	.slick-dots {
		width:calc(100% - var(--bs-gutter-x));
	}

	.product-prev,
	.product-next {
		display: block;
		position: absolute;
		z-index: 100;
		top:0;
		width: 20px;
		height: 100%;
		background-color: transparent;
		border:none;
		color: var(--primary-01);
	}

	.product-prev {
		left:0;
			-moz-transform: rotate(180deg);
			-webkit-transform: rotate(180deg);
			-o-transform: rotate(180deg);
			-ms-transform: rotate(180deg);
			transform: rotate(180deg);
			transform-origin: center;
	}

	.product-next {
		right:0;
	}

	.drn-icon-button-arrow {
		display: block;
	    position: absolute;
	    left: 50%;
	    top: 50%;
	    -moz-transform: translateX(-50%) translateY(-50%);
	    -webkit-transform: translateX(-50%) translateY(-50%);
	    -o-transform: translateX(-50%) translateY(-50%);
	    -ms-transform: translateX(-50%) translateY(-50%);
	    transform: translateX(-50%) translateY(-50%);
	}
}

.upselling-complete-title {
	display: block;
    position: relative;
    width: 100%;
    color: currentColor;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 1em;
}

.upselling-complete-element {
	display:block;
	position:relative;
}

.upselling-complete-element-wrapper{
	display:block;
	position:relative;
	height: 100%;
}

.upselling-complete-close {
	display:inline-block;
	position:absolute;
	z-index:100;
	top:1.5em;
	right:calc(var(--bs-gutter-x) * .5);
	width:32px;
	padding-left: 0;
    padding-right: 0;
	text-align:center;
	font-size: 10px;
    line-height: 1em;
	color:inherit;
	cursor: pointer;
}

// category product area's are 4:3 ratio

/*
@media (min-width: 1400px){
	.col-6.col-md-6.col-lg-6.col-category-product .category-product-holder { // 2 kols

	}
	.col-6.col-md-6.col-lg-4.col-category-product .category-product-holder { // 3 kols
		height: 360px;
	}
	.col-6.col-md-6.col-lg-3.col-category-product .category-product-holder { // 4 kols
		height: 290px;
	}
}

@media (max-width: 1399px){
	.col-6.col-md-6.col-lg-6.col-category-product .category-product-holder { // 2 kols

	}
	.col-6.col-md-6.col-lg-4.col-category-product .category-product-holder { // 3 kols
		height: 340px;
	}
	.col-6.col-md-6.col-lg-3.col-category-product .category-product-holder { // 4 kols
		height: 270px;
	}
}

@media (max-width: 1199px){
	.col-6.col-md-6.col-lg-6.col-category-product .category-product-holder { // 2 kols

	}
	.col-6.col-md-6.col-lg-4.col-category-product .category-product-holder { // 3 kols
		height: 300px;
	}
	.col-6.col-md-6.col-lg-3.col-category-product .category-product-holder { // 4 kols

	}
}

@media (max-width: 991px){
	.col-6.col-md-6.col-lg-6.col-category-product .category-product-holder { // 2 kols

	}
	.col-6.col-md-6.col-lg-4.col-category-product .category-product-holder { // 3 kols
		height: 300px;
	}
	.col-6.col-md-6.col-lg-3.col-category-product .category-product-holder { // 4 kols

	}
}

@media (max-width: 767px){
	.col-6.col-md-6.col-lg-6.col-category-product .category-product-holder { // 2 kols

	}
	.col-6.col-md-6.col-lg-4.col-category-product .category-product-holder { // 3 kols
		height: 300px;
	}
	.col-6.col-md-6.col-lg-3.col-category-product .category-product-holder { // 4 kols

	}
}

@media (max-width: 575px){
	.col-6.col-md-6.col-lg-6.col-category-product .category-product-holder { // 2 kols

	}
	.col-6.col-md-6.col-lg-4.col-category-product .category-product-holder { // 3 kols
		height: calc(100vw * 0.5);
	}
	.col-6.col-md-6.col-lg-3.col-category-product .category-product-holder { // 4 kols

	}
}

*/