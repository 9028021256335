#accountslider-body-login, #accountslider-body-register {
	display: block;
	position: relative;
}

#accountslider-body-login.hidden, #accountslider-body-register.hidden {
	display: none;
}

#accountslider-login-forgotton, #slider-inloggen-footer {
	display: block;
	position: relative;
	width: 100%;
	padding-top: 10px;
    padding-bottom: 10px;
	text-decoration: underline;
	text-align: center;
}

#accountslider-backdrop {
	display: block;
	position: fixed;
	z-index: 100000;
	left:0px;
	top:0px;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0.5);

	visibility: hidden;
	opacity: 0;
}

.accountslider {
	display: block;
	position: fixed;
	z-index: 100500;
	top:0px;
	right:-281px;
	width:280px;
	min-height: 200px;
	background-color: #FFFFFF;
	border: #f3f5f5 1px solid;
	border-top:none;
	-webkit-box-shadow: 3px 3px 10px 0 rgba(0,0,0,0.2);
	box-shadow: 3px 3px 10px 0 rgba(0,0,0,0.2);

	input[type='text'],
	input[type='email'],
	input[type='password'],
	select {
		font-size: 1em !important;
	}

	h2 {
		display: block;
		position:absolute;
		top:50%;
		left:0;
		margin:0;
		width: 100%;
		font-size: 12px;
    	letter-spacing: 1px;
		text-align: left;
		padding-left: 20px;
		padding-top: 6px;
		color:#000000;
		-moz-transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
	}

	h2 a, h2 a:hover, h2 a:visited, h2 a:active {
		text-decoration: none;
		color:#000000;
		padding-top: 10px;
    	padding-bottom: 10px;
	}

	h3 {
		margin-top:0px;
		font-size: 22px;
	}

	p {
		line-height: 1.5em;
	}

	.accountslider-radio {
		display: inline-block;
		margin-right: 5px;
	}

	.form-group {
		margin-bottom:10px;
	}

	.row {
		margin-right: -15px;
    	margin-left: -15px;
	}

	.form-group.padding-right {
		padding-right: 5px;
	}
	.form-group.padding-left {
		padding-left: 5px;
	}
}

.accountslider-label {
	display: block;
	position: absolute;
	top:0;
	left:-35px;
	width: 34px;
	height:50px;
	background-color: #000000;
	z-index: 500;
	cursor: pointer;

	&:before, &:after {
		content:"";
		display: block;
		position: absolute;
		width: 13px;
		left: 8px;
		height:3px;
		background-color: #FFFFFF;
		-webkit-transition: all 300ms ease-in-out;
	    -moz-transition: all 300ms ease-in-out;
	    -ms-transition: all 300ms ease-in-out;
	    -o-transition: all 300ms ease-in-out;
	    transition: all 300ms ease-in-out;
	}

	&:before {
		top: 18px;
    	// width: 20px;
    	// left: 13px;
		-moz-transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
		-o-transform: rotate(-45deg);
		-ms-transform: rotate(-45deg);
		transform: rotate(-45deg);
	}
	&:after {
	    top: 26px;
		// width: 20px;
		// left: 13px;
		-moz-transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
		-o-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}

	.accountslider-label-text {
		display: none;
	}
}

.accountslider-label.closed {
	&:before, &:after {
		width: 20px;
		left: 7px;
		top: 22px;
	}
}

.btn-accountslider-holder {
	display: block;
	position: relative;
	width: 100%;
	text-align: center;
}

// .btn-accountslider {
// 	-webkit-border-radius: 0;
// 	border-radius: 0;
// 	text-transform: uppercase;
// 	color:#000000;
// 	background-color: #f1cdae;
// 	font-size: 15px;
// 	text-align: left;

// 	&:after {
// 		display: block;
// 	    position: absolute;
// 	    content: "";
// 	    z-index: 1;
// 	    right: 0;
// 	    top: 0;
// 	    width: 50px;
// 	    height: 100%;
// 	    text-align: center;
// 	    background-color: #000;
// 	    background-image: url('../../drncards/img/icons/icon-arrow-right-white.svg');
// 	    background-repeat: no-repeat;
// 	    background-position: 50%;
// 	    background-size: 20%;
// 	}
// }

.accountslider.show {
	right:-1px;
}

.accountslider-header {
	display: block;
	position:absolute;
	z-index: 10;
	top:0px;
	left:0px;
	width: 100%;
	height: 50px;
	background-color: #FFFFFF;
	border-bottom: 1px #cccccc solid;
}

#slider-registreren.selected, #slider-inloggen.selected {
	border-bottom: 1px #000000 solid;
	padding-bottom: 3px;
}

.accountslider-container {
	display: block;
	position: relative;
	width: 100%;
	margin-top:50px;
	padding: 20px;
    height: 100vh;
    overflow-y: scroll;
}

.accountslider-body {
	display: block;
	position: relative;
	width: 100%;
	padding-bottom: 150px;
	p, .form-group, label, a {
		font-size: 12px;
		line-height: 1.5em;
	}
}

/* Landscape tablets and medium desktops */
@media (max-width: 1199px) {

}

@media (max-width:767px){

	.accountslider-label {
		left: auto;
    	right: 12px;
    	background: transparent;
    	width: 40px;

    	&:before,
    	&:after {
    		background-color:#000;
    		height: 1px;
    		width: 12px;
    		left: 13px;
    		top: 19px;
    	}

    	.accountslider-label-text {
			display: block;
			position:absolute;
			bottom:8px;
			width: 100%;
			text-align: center;
			text-align: center;
		    font-size: 10px;
		    line-height: 1em;
		    color: inherit;
		}
	}

	.accountslider-label.closed:after,
	.accountslider-label.closed:before {
	    width: 12px;
	    left: 13px;
	    top: 19px;
	}

	.accountslider {
		// top:187px;
	}
	.accountslider-container {
		//max-height: 65vh;
	}

}

.accountslider-loadingdots:after {
	content: ' .';
	animation: loadingdots 1s steps(5, end) infinite;
}

@keyframes loadingdots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);
  }
  40% {
    color: white;
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);
  }
  60% {
    text-shadow:
      .25em 0 0 white,
      .5em 0 0 rgba(0,0,0,0);
  }
  80%, 100% {
    text-shadow:
      .25em 0 0 white,
      .5em 0 0 white;
  }
}

@media (min-width:768px){
	.accountslider {
		width:380px;
		right:-381px;
	}
}

@media screen and (max-width: 520px) {
	.accountslider {
		// top:204px;
	}
}