#calculatorModal {
	iframe {
		height: calc(100vh - 250px);
	}
}

// old calulator

.calculate-options-cta {
	background-color:#e0d9dd;
}

@media (max-width: 991px) {
	#popup_calculator {

	}
}

// Nieuwe calculator

:root {
  --primary-calculation_color: #D7C0AE; // TODO: make dynamic
}

#calculatorHolder {
	display: flex;
	flex-direction: column;
	position: fixed;
	z-index: 999999;
	right:0;
	top:0;
	width:100%;
	max-width: 320px;
	height: 100vh;
	background-color: #FFFFFF;

	.calculatorheader,
	.calculatorfooter {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		width: 100%;
		height: 50px;
		color:#FFFFFF;
		padding-top:0px;
		padding-bottom:0px;
		padding-left:25px;
		padding-right:10px;
		background-color: var(--primary-calculation_color);
	}

	.calculationTitle {
		display: block;
		position: relative;
		border-bottom: 1px var(--primary-calculation_color) solid;
	}

	.calculatorfooter {
		height:70px;
	}

	.calculatorfootertext {
		width: 60%;
	}
	.calculatorfooterprice {
		width: 40%;
		text-align: right;
	}

	.calculatorcontent {
		height: calc(100vh - 120px); // minus header and footer height
		padding:25px;
		overflow-y: auto;
	}

	.calculatorcontent::-webkit-scrollbar {
	  width: 10px;
	}

	.calculatorcontent::-webkit-scrollbar-track {
	  background: #f1f1f1; 
	}
	 
	.calculatorcontent::-webkit-scrollbar-thumb {
	  background: #888; 
	}

	.calculatorcontent::-webkit-scrollbar-thumb:hover {
	  background: #555; 
	}

	#calculatorCalculationResult {
		display: block;
		position: relative;
		list-style: none;
		padding:0;
		margin:0;
		li {
			display: block;

		}
	}
}