.feedback-holder {
	margin-bottom: 4px;
	font-size: 11px;
	line-height: 1em;
}

.feedback-starholder, .feedback-rating {
	display: inline-block;
	position: relative;
}

.feedback-rating {
	margin-left:0.5em;
}

.feedback-star {
	display: inline-block;
	position: relative;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	width: 13px;
	height:13px;
	margin-top: -2px;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}

.feedback-star-half {
	background-image: url('/images/feedback/feedback-star-half.png');
	padding-left: 13px;
}

.feedback-star-full {
	background-image: url('/images/feedback/feedback-star-full.png');
	padding-left: 13px;
}