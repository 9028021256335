#text-header {
	display: block;
	position: relative;
	padding:0.5em;
	text-align: center;
}

#main-header {
	display: block;
	position: relative;
	width: 100%;
}

#logo-header {
	display: block;
	position: relative;
	width: 100%;
	background-color: #FFFFFF;

	.logo-holder {
		padding-top:10px;
		padding-bottom: 10px;
    	max-height: 110px;

		a {
			display: block;
			position: relative;
			width: 100%;
			height: 100%;
		}
	}

	.logo-header-img {
		display: block;
	    position: relative;
	    width: 100%;
	    max-width: 250px;
	    height: auto;
	    max-height: 100%;
	    object-fit: contain;
	}

	.logo-header-img-default {
		object-position: left top;
	}

	.logo-header-img-v1 {
		object-position: center top;
	}

	.logo-header-account {
		display: block;
		position: relative;
		padding:0;
		margin:0;
		list-style: none;
		
		li {
			display: block;
			position: relative;
			float:left;
		}

		a {
			display: block;
			position: relative;
			padding-top:0.5vw;
			padding-bottom:0.5vw;
			padding-right:0.5vw;
			padding-left:0.5vw;
			color:inherit;
			text-decoration: none;
			text-align: center;
		}

		li:first-child a {
			padding-left:0;
		}

		li:last-child a {
			padding-right:0;
		}
	}

	.header-icon-like-notification {
	    display: block;
	    position: absolute;
	    right: 4px;
	    top: 0;
	    line-height: 1;
	    font-size: 10px;
	    background-color: var(--primary-01);
	    color: #FFFFFF;
	    -webkit-border-radius: 50rem;
	    border-radius: 50rem;
	    padding-top: 0.2em;
	    padding-bottom: 0.4em;
	    padding-left: 0.4em;
	    padding-right: 0.4em;
		text-align: center;
		white-space: nowrap;
		vertical-align: baseline;
	}
}

.logo-header-v1 {
	.logo-header-img {
		margin:0 auto;
	}
}

#menu-header {
	display: block;
	position: relative;
	width: 100%;
}

#mp_mainmenu{
	.mp_menuitem.is_private {
		background-color: #000000;
		a {
			color:#FFFFFF;
		}
	}
}

.header-icon-label {
	text-transform: lowercase;
	font-size: 10px;
	line-height: 1em;
}

#logo-header-cart-holder {
	display: inline-block;
    position: relative;
}

#logo-header-cart-holder.loading:before {
	content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255, 0.6);
}
#logo-header-cart-holder.loading:after {
    content: '';
    position: absolute;
    top: -2px;
    left: 24px;
    width: 12px;
    height: 12px;
    border-radius: 12px;
    border: 2px solid grey;
    border-top-color: black;
    animation: loading 1s linear infinite;
  }

/* ---- media queries min-width ---- */

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

	.sticky-header-desktop.sticky {
	    position: fixed;
	    width: 100%;
	    left: 0;
	    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.11);
	    top: 0;
	    z-index: 10000;
	}

	#mp_mainmenu_backdrop {
		display: none;
	}

	#mp_mainmenu {
		display: block;
		position: relative;
		width: 100%;
		left: 0;
	    padding-right: 3rem;
	    padding-left: 3rem;
	    margin-right: auto;
	    margin-left: auto;
	}

	#mp_mainmenu_list {
		display: block;
	    position: relative;
	    text-align: center;
	    margin: 0 auto;
	    padding:0;
	    width: 100%;
	}

	#mp_mainmenu_list.left {
		text-align: left;
		li:first-child a {
			padding-left:0px;
		}
	}

	#mp_mainmenu_list.center {
		li:first-child a {
			padding-left:0px;
		}
		li:last-child a {
			padding-right:0px;
		}
	}

	#mp_mainmenu_list.justify {
		display: flex;
		justify-content: space-between;
		li:first-child a {
			padding-left:0rem;
		}
		li:last-child a {
			padding-right:0rem;
		}
	}

	#mp_mainmenu_list.right {
		text-align: right;
		li:last-child a {
			padding-right:0px;
		}
	}

	#mp_mainmenu_list a {
		text-decoration: none;
	}

	// firste level

	#mp_mainmenu > ul > li {
		display: inline-block;
		position: relative;
	}

	#mp_mainmenu > ul > li > a {
		display: block;
	    position: relative;
		padding: 5px;
	}

	// second level

	#mp_mainmenu > ul > li > ul {
		position: absolute;
		left: 0;
		top: 100%;
		z-index: 600;
		visibility: hidden;
		opacity: 0;
		-webkit-box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 15px -3px, rgba(0, 0, 0, 0.1) 0px 4px 6px -2px;
		box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 15px -3px, rgba(0, 0, 0, 0.1) 0px 4px 6px -2px;
	}

	#mp_mainmenu > ul > li > ul > li {
		list-style: none;
		padding: 10px 12px;
	}

	#mp_mainmenu > ul > li > ul > li a {
		display: block;
	}

	#mp_mainmenu > ul > li:hover > ul {
		visibility: visible;
		opacity: 1;
		transition: all 250ms ease;
	}

	.mp_menuitem {
		transition: all 250ms ease;
	}

	.mp_menuitem.selected {
		border-bottom: #990000 2px solid;
	}

	.mp_mainmenu_sub_nav {
		padding:0;
	}

	.mp_menuitem:hover > a,
	.mp_mainmenu_sub_nav_content li:hover a {
		// background-color: #cccccc;
	}

	.mp_mainmenu_sub_nav_content {
		text-align: left;
	    display: block;
	    position: relative;
	}

	.mp_mainmenu_sub_nav_content ul {
		display: block;
		position: relative;
		width: 100%;
		padding:0;
		margin:0;
		list-style: none;
	}

	.mp_mainmenu_sub_nav_1 {
		width: 210px;
		.mp_mainmenu_sub_nav_content {
			width: 210px;
			float: left;
		}
	}

	.mp_mainmenu_sub_nav_2 {
		width: 420px;
		.mp_mainmenu_sub_nav_content {
			width: 210px;
			float: left;
		}
	}

	.mp_mainmenu_sub_nav_3 {
		width: 630px;
		.mp_mainmenu_sub_nav_content {
			width: 210px;
			float: left;
		}
	}

	.mp_mainmenu_sub_nav_4 {
		width: 840px;
		.mp_mainmenu_sub_nav_content {
			width: 210px;
			float: left;
		}
	}

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {

}

// XXX-Large devices (larger desktops, 1600px and up)
@media (min-width: 1600px){
	#mp_mainmenu_list {
	    max-width: 1600px;
	}

	#mp_mainmenu_list.justify {
		li:first-child a {
			padding-left:3rem;
		}
		li:last-child a {
			padding-right:3rem;
		}
	}
}

/* ---- media queries max-width ---- */
@media (max-width: 1399px){
	
}

@media (max-width: 1199px){
	
}

@media (max-width: 991px){
	.mp_menuitem {
		.drn-icon {
			width: 14px;
			height: 14px;
}
	}
}

@media (max-width: 767px){

	.sticky-header-mobile.sticky {
	    position: fixed;
	    width: 100%;
	    left: 0;
	    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.11);
	    top: 0;
	    z-index: 10000;
	}

	#logo-header {
		padding-top:10px;
		padding-bottom:10px;
		a, a:hover, a:visited, a:active {
			color: inherit;
			text-decoration: none;
		}
	}

	#mp_mainmenu_backdrop {
		display: block;
		position: fixed;
		left:-100%;
		top:0;
		z-index: 600;
		width: 100%;
		height: 100%;
		opacity: 0;
		background-color: rgba(0,0,0,0.3);

		-webkit-transition: opacity 200ms ease-in-out;
		-moz-transition: opacity 200ms ease-in-out;
		-ms-transition: opacity 200ms ease-in-out;
		-o-transition: opacity 200ms ease-in-out;
		transition: opacity 200ms ease-in-out;
	}

	#mp_mainmenu_backdrop.open {
		left:0;
		opacity: 1;
	}

	#mp_mainmenu {
		display: block;
		position: fixed;
		z-index: 1500;
		overflow-y: auto;
		width: 80vw;
		height: 100vh;
		max-height: 100vh;
		left: calc(-80vw - 15px);
		top:0;
		margin-bottom:15px;

		-webkit-box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 15px -3px, rgba(0, 0, 0, 0.1) 0px 4px 6px -2px;
		box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 15px -3px, rgba(0, 0, 0, 0.1) 0px 4px 6px -2px;

		-webkit-transition: left 200ms ease-in-out;
		-moz-transition: left 200ms ease-in-out;
		-ms-transition: left 200ms ease-in-out;
		-o-transition: left 200ms ease-in-out;
		transition: left 200ms ease-in-out;
		
		-moz-transform-origin: left;
	    -webkit-transform-origin: left;
		-o-transform-origin: left;
		-ms-transform-origin: left;
	    transform-origin: left;
	}

	#mp_mainmenu .mobilemenu_toggle {
		color:inherit;
		text-decoration: none;

		// set icon hidden
		.drn-icon {
			display: none;
		}
	}

	#mp_mainmenu #mobilemenu_header_container {
		display: block;
		position:fixed;
		width: inherit;
		padding-bottom:15px;
		z-index: 10;
	}

	#mp_mainmenu .mobilemenu_header {
		margin-left: 0;
		margin-right: 0;
		padding-left: 15px;
		padding-right: 15px;
		max-width: 100%;
	}

	#mp_mainmenu.open {
		left: 0;
		top:0;
	}

	#mp_mainmenu ul {
		display: block;
		position: relative;
		width: 100%;
		margin:0;
		padding:0;
		list-style: none;
	}

	#mp_mainmenu > ul {
		padding-left:15px;
		padding-right: 15px;
		padding-bottom: 100px;
	}

	#mp_mainmenu > ul > li > a {
		display: block;
		position: relative;
		font-size: 1.3em;

		.drn-icon {
			display: none;
		}

		.mp_submenu-icon {
			display: block;
			position: absolute;
			right:0;
			top:50%;
			width: 32px;
			height: 32px;
			-moz-transform: translateY(-50%);
			-webkit-transform: translateY(-50%);
			-o-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			transform: translateY(-50%);
		}

		.mp_submenu-icon {
			&:before {
				content: "";
				position: absolute;
				display: block;
				background-color: var(--primary-01);
				z-index: 5;
				width: 10px;
				height: 1px;
				left:50%;
				top:50%;
				-moz-transform: translateY(-50%) translateX(-50%);
				-webkit-transform: translateY(-50%) translateX(-50%);
				-o-transform: translateY(-50%) translateX(-50%);
				-ms-transform: translateY(-50%) translateX(-50%);
				transform: translateY(-50%) translateX(-50%);

				-webkit-transition: all 200ms ease-in-out;
				-moz-transition: all 200ms ease-in-out;
				-ms-transition: all 200ms ease-in-out;
				-o-transition: all 200ms ease-in-out;
				transition: all 200ms ease-in-out;
			}

			&:after {
				content: "";
				position: absolute;
				display: block;
				background-color: var(--primary-01);
				z-index: 5;
				width: 1px;
				height: 10px;
				left:50%;
				top:50%;
				-moz-transform: translateX(-50%) translateY(-50%);
				-webkit-transform: translateX(-50%) translateY(-50%);
				-o-transform: translateX(-50%) translateY(-50%);
				-ms-transform: translateX(-50%) translateY(-50%);
				transform: translateX(-50%) translateY(-50%);

				-webkit-transition: all 200ms ease-in-out;
				-moz-transition: all 200ms ease-in-out;
				-ms-transition: all 200ms ease-in-out;
				-o-transition: all 200ms ease-in-out;
				transition: all 200ms ease-in-out;
			}
		}

		.mp_submenu-icon.open {
			&:after {
				-moz-transform: translateX(-50%) translateY(-50%) rotate(45deg);
				-webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
				-o-transform: translateX(-50%) translateY(-50%) rotate(45deg);
				-ms-transform: translateX(-50%) translateY(-50%) rotate(45deg);
				transform: translateX(-50%) translateY(-50%) rotate(45deg);
			}

			&:before{
				-moz-transform: translateX(-50%) translateY(-50%) rotate(45deg);
				-webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
				-o-transform: translateX(-50%) translateY(-50%) rotate(45deg);
				-ms-transform: translateX(-50%) translateY(-50%) rotate(45deg);
				transform: translateX(-50%) translateY(-50%) rotate(45deg);
			}
		}
	}

	.mp_menuitem {
		margin-top:30px;
	}

	.mp_mainmenu_sub_nav {
		padding:0;
		height: 0;
		overflow: hidden;
	}

	.mp_mainmenu_sub_nav_content {
		margin-top:1em;
	}

	.mp_mainmenu_sub_nav_content li {
		display: block;
		position: relative;
		width: 100%;;
	}

	.mp_mainmenu_sub_nav_content a {
		display: block;
		position:relative;
		width: 100%;
		padding-left:15px;
		padding-top:15px;
		padding-bottom:15px;
		border-bottom: 1px solid var(--primary-01);
	}

	.header-icon-label {
		display: block;
	}

}

@media (max-width: 374px){
	.header-icon-label {
		display:none;
	}
}