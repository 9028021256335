/* ---- product slider ---- */

.wl-productslide {
	display: block;
	position: relative;
	min-height:50px;
	padding-left:10px;
	padding-right:10px;
	padding-bottom:10px;
	padding-top:30px;
	outline: none;
	overflow: visible;
}

.wl-productslide-imageholder {
	display: block;
    position: absolute;
    width: 120px;
    height: 180px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(.8);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.wl-productslide-container {
	display:block;
	position:relative;
	width:100%;
	height:180px;
    margin-bottom: 55px;
    text-align: center;

	img {
		position: relative;
		object-fit: contain;
		display: block;
		width:100%;
		height:100%;
		max-width: 100%;
    	max-height: 100%;
		// image-rendering: -webkit-optimize-contrast;
		-webkit-backface-visibility: hidden; 
	    -ms-transform: translateZ(0); /* IE 9 */
	    -webkit-transform: translateZ(0); /* Chrome, Safari, Opera */
	    transform: translateZ(0);
	}

	// img.contain {
	// 	object-fit: contain;
	// }

    img.dropshadow {
        // box-shadow: 1px 1px 6px rgba(0,0,0,.3);
        filter: drop-shadow(1px 1px 3px rgba(0,0,0,0.3));
    }
}

.wl-productslider-notitle .wl-productslide-container {
	margin-bottom: 0;
}

.wl-productslide-title {
    display: block;
    position: absolute;
    z-index: 10;
    width: 100%;
    left:0;
    top:200px;
    line-height: 1.2em;
}

.wl-productslide-container-small {
    height:110px;

    .wl-productslide-title {
    	top:130px;
    }
}

.slick-track{
    display: flex !important;
    margin-left: 0;
}

.slick-slide{
    height: inherit !important;
}

.slick-slide > div {
	display: flex;
	justify-content: center;
	position: relative;
	height:100%;
}

// content slider progressbar
.slidable-content-2 .slick-dots,
.slidable-content-3 .slick-dots,
.slidable-content-4 .slick-dots {
	padding-left: 15px;
    padding-right: 15px;
    bottom:-10px;
}

#upsell-wrapper .slick-dots,
#upselling-complete-slider .slick-dots,
.card_holder_slick_product .slick-dots,
.card_holder_slick_category .slick-dots,
.card_holder_slick_category_cart .slick-dots,
.slidable-content-2 .slick-dots,
.slidable-content-3 .slick-dots,
.slidable-content-4 .slick-dots,
.imagegallery-5 .slick-dots,
.imagegallery-4 .slick-dots,
.imagegallery-3 .slick-dots,
.imagegallery-2 .slick-dots,
.imagegallery-1 .slick-dots {

	z-index: 10;
	
	li {
		position: relative;
		height: 3px;
		width: 50px;
		margin:0;
		// small seperator between dots
		padding-left:2px;
		padding-right:2px;
	}

	li .slidable-content-pagination-item {
		background-color: rgba(0,0,0,0.1);
	}

	li:first-child .slidable-content-pagination-item {
		-webkit-border-radius: 4px 0 0 4px;
		border-radius: 4px 0 0 4px;
	}
	li:last-child .slidable-content-pagination-item {
		-webkit-border-radius: 0 4px 4px 0;
		border-radius: 0 4px 4px 0;
	}

	li:first-child {
		-webkit-border-radius: 4px 0 0 4px;
		border-radius: 4px 0 0 4px;
		padding-left:0px;
	}
	li:last-child {
		-webkit-border-radius: 0 4px 4px 0;
		border-radius: 0 4px 4px 0;
		padding-right:0px;
	}

	li.slick-active .slidable-content-pagination-item {
		background-color: var(--primary-01);
	}

	.slidable-content-pagination-item {
		display: block;
		position: relative;
		width: 100%;
		height: 100%;
	}
	
}

@media (max-width: 767px){
	// tweede kaartje half in beeld
	.wl-productslider .slick-list,
	.slidable-content-2 .slick-list,
	.slidable-content-3 .slick-list,
	.slidable-content-4 .slick-list {
		padding:0 20% 0 0;
	}
}

@media (max-width: 480px){
	// tweede kaartje half in beeld
	.wl-productslider .slick-list,
	.slidable-content-2 .slick-list,
	.slidable-content-3 .slick-list,
	.slidable-content-4 .slick-list {
		padding:0 40% 0 0;
	}
}