.custom-footer {
	display: block;
	position:relative;
	width: 100%;
}

#pre-footer {
	display: block;
	position:relative;
	width: 100%;
	min-height: 25px;
	background-color:#F4F4F4;

	.footer-payment-icon {
		display: inline-block;
		position: relative;
	    min-height: 15px;
	    height: 1.6vw;
	    max-height: 20px;
		width: auto;
		margin-right:1em;
	}
}

#main-footer {
	display: block;
	position:relative;
	width: 100%;
	min-height:50px;

	ul {
		display: block;
		position: relative;
		padding:0;
		margin:0;
		list-style: none;
		overflow: hidden;
	}

	li {
		display: block;
		position: relative;
		width: 100%;
		padding:0;
		margin:0;
	}

	a {
		text-decoration: none;
	}

	a:hover {
		text-decoration: underline;
	}

	.social_links {
		li {
			display: inline-block;
			width: auto;
			margin-right:15px;
		}
	}
}

.footer-heading {
	display: block;
	position: relative;
	padding-bottom:10px;
	margin-bottom:10px;
}

.footer-heading .drn-icon {
	-webkit-transition: all 200ms ease-in-out;
	-moz-transition: all 200ms ease-in-out;
	-ms-transition: all 200ms ease-in-out;
	-o-transition: all 200ms ease-in-out;
	transition: all 200ms ease-in-out;
}

.footer-heading:after {
	content:'';
	display: block;
	position: absolute;
	left:0;
	bottom:0;
	width: 75%;
	height: 1px;
	background-color:currentColor;
}

.footermenu-content-expander {
	display: block;
	position: relative;
	width: 100%;
	overflow: hidden;
}

#copyright-footer {
	display: block;
	position: relative;
	width: 100%;
	min-height: 20px;

	ul {
		display: inline-block;
		position: relative;
		width:auto;
		padding:0;
		margin:0;
		list-style: none;
	}

	li {
		display: inline-block;
		position: relative;

	}
}

#back-to-top {
	cursor: pointer;
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 35px;
    height: 35px;
    line-height: 0;
    z-index: 9000;
    font-size: 38px;
    color: #fff;
    background-color: rgba(0,0,0,.2);
    text-align: center;
    padding-top: 0;
    padding-bottom: 0;
    box-shadow: 0 0 0 1px hsl(0deg 0% 100% / 40%);
    display: none;

    .drn-icon {
    	display: block;
    	position: absolute;
    	left:50%;
    	top:50%;
    	transform-origin: center;
    	-moz-transform: translateX(-50%) translateY(-50%) rotate(180deg);
		-webkit-transform: translateX(-50%) translateY(-50%) rotate(180deg);
		-o-transform: translateX(-50%) translateY(-50%) rotate(180deg);
		-ms-transform: translateX(-50%) translateY(-50%) rotate(180deg);
		transform: translateX(-50%) translateY(-50%) rotate(180deg);
    }
}

@media (min-width: 767px){
	.footermenu-content-expander {
		height: auto !important;
	}
}

@media (max-width: 767px){
	#pre-footer,
	#main-footer,
	#copyright-footer {
		padding-left:5px;
		padding-right:5px;
	}

	#pre-footer {
		text-align: center;
		.d-flex {
			display: block !important;
			margin-bottom: 1em;
		}
	}

	#main-footer {
		.footer-heading:after {
			width: 100%;
		}
		.footer-heading.open .drn-icon {
			-moz-transform: rotate(180deg);
			-webkit-transform: rotate(180deg);
			-o-transform: rotate(180deg);
			-ms-transform: rotate(180deg);
			transform: rotate(180deg);
		}
		.footermenu-content-expander {
			height: 0;
		}
	}

	#back-to-top {
	}
}