.drnTooltip {
    display:inline-block;
    position:relative;
    text-align:left;
}

.drnTooltip .drnTooltipRight,
.drnTooltip .drnTooltipLeft {
    min-width:200px; 
    max-width:250px;
    top:50%;
    left:100%;
    margin-left:20px;
    transform:translate(0, -50%);
    padding-left:0.5em;
    padding-right:0.5em;
    padding-top:0.3em;
    padding-bottom:0.3em;
    color:#FFFFFF;
    background-color:#000000;
    font-weight:normal;
    font-size:13px;
    line-height: 1.4em;
    position:absolute;
    z-index:99999999;
    box-sizing:border-box;
    border:1px solid #000000;
    box-shadow:0 1px 8px rgba(0,0,0,0.5);
    visibility:hidden;
    opacity:0;
    transition:opacity 0.8s;
}

.drnTooltip .drnTooltipRight i,
.drnTooltip .drnTooltipLeft i {
    position:absolute;
    top:50%;
    right:100%;
    margin-top:-1em;
    width:1em;
    height:2em;
    overflow:hidden;
}

.drnTooltip .drnTooltipRight i::after,
.drnTooltip .drnTooltipLeft i::after {
    content:'';
    position:absolute;
    width:1em;
    height:1em;
    left:0;
    top:50%;
    transform:translate(50%,-50%) rotate(-45deg);
    background-color:#000000;
    border:1px solid #000000;
    box-shadow:0 1px 8px rgba(0,0,0,0.5);
}

.drnTooltip .drnTooltipLeft {
    left:auto;
    right:100%;
    margin-left: 0;
    margin-right:20px;
}

.drnTooltip .drnTooltipLeft i {
    right:auto;
    left:100%;
}

.drnTooltip .drnTooltipLeft i::after {
    transform:translate(-50%,-50%) rotate(-45deg);
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    .drnTooltip:hover .drnTooltipRight,
    .drnTooltip:hover .drnTooltipLeft {
        visibility:visible;
        opacity:1;
    }
}