// menu
.namen-alfabet {
	display:block;
	position:relative;
	width:100%;
	padding:0 !important;
	margin:0;
	list-style:none;
	text-align: center;
	max-width: 620px;
    margin: 0 auto;

	li {
		display: inline-block;
		padding:0;
		margin:0;
	}

	a {
	    display: block;
	    position: relative;
	    width: 1.2em;
	    text-align: center;
		margin: .7em;
		font-size: 1.4em;
	    text-transform: uppercase;
	    font-weight: normal;
	    text-decoration: none;
	}

	a:hover {
		font-weight: bold;
	}

	a:after {
		display: block;
		position: absolute;
		opacity: 0;
		content:'';
		width: 100%;
		height: 1px;
		background-color: #000000;
		left:0;
		top:0;
		margin-top:1.4em;
	}

	a:hover:after {
		opacity: 1;
	}

	a.active:after {
		opacity: 1;
	}

	a.active {
		font-weight: bold;
	}
}

.namen-lijst {
	display:block;
	position: relative;
	list-style: none;
	margin:0 auto;
	padding-left: 0 !important;
	padding-right: 0 !important;
	margin-top:1vw;
	width:100%;
	text-align: left;
}

.namen-lijst-naam {
	display: inline-block;
	width: 20%;
	text-align: center;
	margin-bottom: 15px;
}

.namen-paginering {
	display:block;
	position: relative;
	margin:0 auto;
	max-width: 620px;
	text-align: center;
	text-transform: uppercase;
	font-size: 1.6em;
	margin-top: 2em;
    margin-bottom: 2em;

	a {
		color:#b3b3b3;
		padding-left: 0.5em;
		padding-right: 0.5em;
		text-decoration: none;
	}

	.pref, .next {
		display: block;
		position: absolute;
		top:50%;
		width: 50px;
	    padding-top: 10px;
	    padding-bottom: 10px;

		-moz-transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);

		-webkit-transition: all 0.2s ease-in-out;
		-moz-transition: all 0.2s ease-in-out;
		-o-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;
	}

	.pref {
		left:0;
		padding-right:10px;
		&:hover {
			left:-10px;
		}
	}

	.active {
		display: inline-block;
		position: relative;
	    font-size: 2.0em;
	    line-height: 1em;
	    text-transform: uppercase;
	    padding-top: 0.1em;
	    padding-bottom: 0.1em;
	}

	.next {
		right: 0;
		padding-right:10px;
		padding-left:0px;
		&:hover {
			padding-right:0px;
			padding-left:10px;
		}
	}
}

.namen-paginering-background {
	display: block;
    position: relative;
    margin: 0 auto;
    width: 150px;
    background-color: #f1eae5;
}