// overrule flexbox to honor text aligning

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p {
	display: block;
	width: 100%;
}

// like bootstrap fs_1 to fs_6 but smaller

.fss-6 {
	font-size: 0.15rem !important;
}

.fss-5 {
	font-size: 0.30rem !important;
}

.fss-4 {
	font-size: 0.55rem !important;
}

.fss-3 {
	font-size: 0.70rem !important;
}

.fss-2 {
	font-size: 0.85rem !important;
}

.fss-1 {
	font-size: 1rem !important;
}