/* ---- horizontal filter ---- */

#category-filters-horizontal {
	display: block;
	position: relative;
	width: 100%;
	padding:0;
	margin:0;
	list-style:none;

	li {
		display: inline-block;
		position: relative;
		margin-right:0.6em;
		margin-bottom:0.6em;
	}

	li a {
		display: inline-block;
	    position: relative;
	    cursor: pointer;
	    text-decoration: none;
	    padding-top:0.4em;
	    padding-bottom:0.4em;
	    padding-left:0.6em;
	    padding-right:0.6em;
	}

	.category-filters-horizontal-isdropdown {
	    display: block;
	    position: absolute;
	    visibility: hidden;
	    overflow: hidden;
	    z-index: 500;
	    min-width: 100%;
	    // padding: 15px;
	    margin: 0;
	    margin-top: -1px;
	    list-style: none;
	    background-color: #fff;
	    opacity: 0;
	    -webkit-transition: all 200ms ease-in-out;
		-moz-transition: all 200ms ease-in-out;
		-ms-transition: all 200ms ease-in-out;
		-o-transition: all 200ms ease-in-out;
		transition: all 200ms ease-in-out;
	}

	.category-filters-horizontal-isdropdown label {
		display: block;
		cursor: pointer;
		padding: 1em;
		white-space: nowrap;
	}

	li.active .category-filters-horizontal-isdropdown {
	    display: block;
	    min-width: 100%;
	    visibility: visible;
	    opacity: 1;
	}

	/* Create a custom checkbox */
	.category-filters-horizontal-checkmark {
		display: inline-block;
		position: relative;
		height: 20px;
		width: 20px;
		margin-bottom: -0.35em;
		margin-right: 0.3em;
	}

	.category-filters-horizontal-item:last-child label {
		border-bottom:none;
	}

	.category-filters-horizontal-item input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	/* On mouse-over, add a grey background color */
	.category-filters-horizontal-item:hover input ~ .category-filters-horizontal-checkmark {
		// background-color: #ccc;
		// border: 1px #ccc solid;
	}

	/* When the checkbox is checked, add a blue background */
	.category-filters-horizontal-item input:checked ~ .category-filters-horizontal-checkmark {
		// background-color: #2196F3;
		// border: 1px #2196F3 solid;
	}

	/* Create the checkmark/indicator (hidden when not checked) */
	.category-filters-horizontal-checkmark:after {
		content: "";
		position: absolute;
		display: none;
	}

	/* Show the checkmark when checked */
	.category-filters-horizontal-item input:checked ~ .category-filters-horizontal-checkmark:after {
		display: block;
	}

	/* Style the checkmark/indicator */
	.category-filters-horizontal-item .category-filters-horizontal-checkmark:after {
		left: 7px;
		top: 3px;
		width: 5px;
		height: 10px;
		border: solid white;
		border-width: 0 3px 3px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}

	.category-filters-horizontal-colorswatch {
		display: inline-block;
		width: 20px;
	    height: 20px;
	    margin-bottom: -0.35em;
	}
}

#category-filters-horizontal-selected {
	ul {
		display: block;
		position: relative;
		list-style: nonoe;
		width: 100%;
		padding:0;
		margin:0;
	}
	li {
		display: inline-block;
		margin-right: 0.6em;
		margin-bottom: 0.6em;
	}
	label {
		display: block;
		position: relative;
		cursor: pointer;
		padding: 0.4em 0.6em;
		background-color: #F4F4F4;
	}
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}
	span {
		padding-left:1em;
		font-weight: bold;
		font-family: Arial;
	}
}