.tag {
	display: inline-block;
	padding: 0.35em 0.65em;
	line-height: 1;
	color: var(--primary-01);
	white-space: nowrap;
	vertical-align: baseline;
	background-color:#FFFFFF;
	border: solid 1px var(--primary-01);
	text-decoration: none;
	margin-bottom: 0.1em;
}