.pagination {
	.drn-icon {
		width: 20px;
		height: 20px;
	}

	.pagination-icon-left {
		-moz-transform: rotate(180deg);
		-webkit-transform: rotate(180deg);
		-o-transform: rotate(180deg);
		-ms-transform: rotate(180deg);
		transform: rotate(180deg);
	}

	.page-item {
		line-height: 1em;
	}

	.page-item .page-link {
		background-color: transparent;
		color: currentColor;
		border: none;
		font-size: inherit;

		&:hover {
			text-decoration: none;
		}
	}

	.page-item.disabled .page-link {
		opacity: 0.5;
	}

	.page-item.active .page-link {
		color: var(--primary-01);
	}

	.page-item .page-link-arrow {
		background-color: #F4F4F4;
		-webkit-border-radius: 0 0 0 0;
		border-radius: 0 0 0 0;
    	padding: 0.375rem 0.5rem;
	}
}