// base css: themes/default_v2/css/vendor/typeahead-2-11-0/jquery.typeahead.scss

.typeahead__group {
	a {
		padding-left: 0.5em !important;
		padding-right: 0.5em !important;
	}
}
.typeahead__item {
	a {
		padding-left: 0.5em !important;
		padding-right: 0.5em !important;
	}
	img {
		width:50px;
		height: auto;
	}
	.product-information {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.title {
		padding-left:1em;
	}
}

.typeahead__cancel-button {
	padding-right:0.2em;
}

#site-search-form-mobile {
	.typeahead__field {
		overflow: hidden;
	}
	.typeahead__result {
		display: block;
	    z-index: 500;
	    position: absolute;
		height: 80vh;
	    overflow-y: scroll;
	    overflow-x: hidden;
	}
}