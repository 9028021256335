.btn {
	border-radius: 0;
	text-align: center;
	padding: 0.75rem 1rem;
	line-height: inherit;

	// ellipsis is text does not fit
	overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
}

.btn:hover {
	text-decoration: none;
}

.btn.disabled, .btn:disabled, fieldset:disabled .btn {
    opacity: 1;
}

.btn-xs {
	// font-size: .875rem;
	font-size: inherit;
    padding: 0.1rem 0.6rem;
}

.btn-sm {
	// font-size: .875rem;
	font-size: inherit;
	padding: 0.50rem 0.75rem;
}

.btn-lg {
	// font-size: 1.25rem;
	font-size: inherit;
}

.btn-block {
	display: block;
    width: 100%;
}

.btn-arrow {
	padding-right:3em;
	position: relative;
	/*
	&:after {
		display: block;
		position: absolute;
		right:0.75rem;
		top:50%;
		content:">";
		-moz-transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
	}
	*/
}

.btn.disabled {
	opacity: 0.65;
	cursor: not-allowed;
	pointer-events: auto;
}

.btn.disabled, .btn:disabled, fieldset:disabled .btn {
	border-color:inherit;
}

.buttonblock {
	display:inline-block;
	max-width: 100%;
	.btn {
		width: 100%;
		margin-bottom: 1em;
	}
}

.btn-action-outline-activated {
	border-color: #198754;
	.btn-cart-continue-text,
	.drn-icon {
		color:#198754;
	}
}

// mediaqueries

@media (max-width: 1399px){ // xl
    
}

@media (max-width: 1199px){ // xl
    
}

@media (max-width: 991px){ // lg
    #cart-backto-editor {
        position: relative;
        text-align: center;
    }
}

@media (max-width: 767px){ // ms
    
}

@media (max-width: 575px){ // sm

}