#editProduct {
    background: url('/images/pen.png') no-repeat 50%;
    width: 20px;
    height: 20px;
    display: block;
    text-decoration: none;
    outline: none;
    position: absolute;
    top: 0;
    right: 40px;
    z-index: 400;
}

.product-wish-content {
	display: block;
    position: relative;

    .drn-icon {
		width: 38px;
    	height: 38px;
	}
}

#product-description {

	ul:not(.slick-dots), ol {
		list-style:none;
			padding-left:0;
	}

	ul:not(.slick-dots) li,
	ol li {
		padding-left: 25px;
		padding-bottom: 0.4em;
	}

	// customer icon loaded by theme_v2.css
	ul:not(.slick-dots) li {
		display: block;
		position: relative;
		width: 100%;
		background-image: url('/images/icons/site-default/list-check.svg');
		background-repeat: no-repeat !important;
		background-position: left 4px  !important;
		background-size: 14px 14px  !important;
	}

	ol li {
		display: block;
		position: relative;
		width: 100%;
		counter-increment: listcounter;
	}

	ol li:before {
		content: counter(listcounter);
		display: block;
		position: absolute;
		left: 0px;
		top:0px;
	}

	.accordion-item {
		border: none;
		background-color: transparent;
	}

	.accordion-header {
    	margin:0;
	}

	.accordion-button {
		padding-top: 0.6rem;
	    padding-right: 0;
	    padding-bottom: 0.6rem;
	    padding-left: 0;
		-webkit-border-radius: 0 0 0 0;
		border-radius: 0 0 0 0;
		-webkit-box-shadow: none;
		box-shadow: none;
		background-color: transparent;
	}

	.accordion-button:not(.collapsed) {
		color: inherit;
	}

	.accordion-button:after {
	    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
	}

	.accordion-button:not(.collapsed):after {
    	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
	}

	.accordion-body {
	    padding-top: 1rem;
	    padding-right: 0;
	    padding-bottom: 0.5rem;
	    padding-left: 0;
	}
}

.product-information-tabs {
	.product-layoutname {
		display: block;
		position: relative;
	}

	.product-layoutname-label {
		font-weight: bold;
	}
}

// product layout for non-monbile, we could make this different based on settings

// #product-description {
// 	width: 100%;
// 	padding-right: 3rem;
// 	padding-left: 3rem;
// 	margin-right: auto;
// 	margin-left: auto;
// }

#btn-edit-product,
#otherProductForm {
	.drn-icon {
		width: 30px;
		height: 30px;
	}
}

/*  mobile styles */
@media (max-width: 767px){

	.product-button-block .container {
		position: fixed;
		left:0;
		bottom:0;
		z-index: 500;
		width: 100%;
		max-width: 100%;
		background-color: #FFFFFF;
		padding-top:20px;
		padding-bottom:20px;
		padding-right:20px;
		padding-left:1.8vw;
		-webkit-box-shadow: 0 0 15px 0 rgba(0,0,0,0.5);
		box-shadow: 0 0 15px 0 rgba(0,0,0,0.5);
	}

	#otherProductFormCalculator {
		display: none !important;
	}

	#copyright-footer {
		padding-bottom:4em;
	}

	#product-description .col-12 {
		flex-direction: column;
		flex-wrap: wrap;
		display: flex;
		position:relative;
		width: 100%;
	}
	
	.product-title-block,
	.product-image-block,
	.product-button-block,
	.product-static-block-1,
	.product-information-tabs,
	.product-static-block-2,
	.product-static-block-3 {
		display:block;
		position: relative;
		width:100%;
	}

	.product-title-block {
		order: 1;
	}

	.product-image-block {
		order: 0;
	}

	.product-button-block {
		order: 2;
		// button is sticky so height must be zero
		margin: 0 !important;
	    padding: 0 !important;
	    height: 0;
	}

	.product-static-block-1 {
		order: 3;
	}

	.product-information-tabs {
		order: 4;
	}

	.product-static-block-2 {
		order: 5;
	}

	.product-static-block-3 {
		order: 6;
	}
}

/* desktop styles */
@media (min-width: 768px){
	#product-description .col-12 {
		display:grid;
		grid-template-columns: 55% 45%;
		grid-template-rows: repeat(6,auto);
	}
	
	.product-title-block,
	.product-image-block,
	.product-button-block,
	.product-static-block-1,
	.product-information-tabs,
	.product-static-block-2,
	.product-static-block-3 {
		display:block;
		position: relative;
		width:100%;
	}

	.product-title-block,
	.product-button-block,
	.product-static-block-1,
	.product-information-tabs,
	.product-static-block-2,
	.product-static-block-3 {
		padding-left:15%;
	}
	
	.product-title-block {
		grid-column:  2 / span 1;
		grid-row:  1 / span 1;
	}

	.product-image-block {
		grid-column:  1 / span 1;
		grid-row:  1 / span 6;
		overflow:hidden;
		padding-right:2em;
	}

	.product-button-block {
		grid-column:  2 / span 1;
		grid-row:  2 / span 1;
	}

	.product-static-block-1 {
		grid-column:  2 / span 1;
		grid-row:  3 / span 1;
	}

	.product-information-tabs {
		grid-column:  2 / span 1;
		grid-row:  4 / span 1;
	}

	.product-static-block-2 {
		grid-column:  2 / span 1;
		grid-row:  5 / span 1;
	}

	.product-static-block-3 {
		grid-column:  2 / span 1;
		grid-row:  6 / span 1;
	}

	#product-formcalulator {
		grid-template-columns: 100% !important;
	}
}

/*
@media (min-width: 768px){
	#product-description {
		.product-flexcontainer {
			display: flex;
			justify-content: flex-start; 
			align-items: flex-start; 
			flex-direction: column; 
			flex-wrap: wrap; 
			align-content: flex-start;
			height: 100%;
			gap: 0px;
		}

		.product-flexitem  {
			display:block;
			position: relative;
			width:50%;
			// align-self:flex-end;
		}

		.product-flexitem-content-r,
		.product-flexitem-content-l {
			display: block;
			position: relative;
			width: 100%;
			// overflow: hidden;
		}

		.product-flexitem-content-r {
			padding-left:3%;
		}

		.product-flexitem-content-l {
			padding-right:3%;
		}

		.product-title-block {
			order:1;
		}

		.product-image-block {
			order:0;
			flex-grow:1;
			flex-basis:100%;
			align-self:flex-start;
			overflow: hidden;
		}

		.product-button-block {
			order:3;
			margin-top: 1.5rem!important;
		}

		.product-static-block-1 {
			order:4;
		}

		.product-information-tabs {
			order:5;
		}

		.product-static-block-2 {
			order:6;
		}

		.product-static-block-3 {
			order:7;
		}
	}
}

*/

// ---- alternative products ----

#product-alternative-products {

    display: flex;
    flex-direction: column;

	// #product-tags {
	//     order: 0;
	// }
	// #product-complete {
	// 	order: 1;
	// }
	// #product-variants {
	// 	order: 2;
	// }
	// #product-upsell {
	// 	order: 3;
	// }
	// #product-envelopes {
	// 	order: 4;
	// }
	// #product-related {
	// 	order: 5;
	// }
}

.product-alternative-products-title {
	display: block;
	position: relative;
	width: 100%;
	color: currentColor;
	font-size:20px;
	line-height: 24px;
	margin-bottom: 1em;
}