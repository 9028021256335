// all fold styles are in: card-folds.scss

.product-thumbnail-slider-holder {
	display: block;
	position: relative;
	width: 100%;

	.slick-slide {
		padding-bottom:12px;
		border-bottom:2px transparent solid;
	}

	.slick-slide.slick-current {
		border-color:var(--primary-01);
	}
}

.product-thumbnail-slide {
	display: block;
	position: relative;
	width: 20%;
	cursor: pointer;
}

.product-thumbnail-media-content {
	margin-bottom:5px;
	img {
	    width: 100%;
	    height: 100%;
	    object-fit: contain;
	    object-position: center bottom;
	}
}

.card_holder_slick_product .slick-dots,
.card_holder_slick_category .slick-dots,
.card_holder_slick_category_cart .slick-dots {
	display: flex;
	flex-direction: row;
}