.block-testimonials-slider {
	display: block;
	position: relative;
	padding:0;
	margin:0;

	.testimonial-image {
		display: block;
	    position: relative;
	    width: 100%;
	    overflow: hidden;
		margin-bottom: 1em;
		background-size: cover;
		background-repeat:no-repeat;
		background-position: center center;
	}

	.testimonial-image-square {
		padding-bottom:100%;
	}

	.testimonial-image-landscape {
		padding-bottom:60%;
	}

	.testimonial-image-portrait {
		padding-bottom:140%;
	}

	.testimonial-image-none {
		padding-bottom:0;
		height: 100%;
		background-size: contain;
		background-position: center top;
	}

	.testimonial-image-none-img {
		display: block;
		max-width: 100%;
		height: auto;
		image-rendering: -webkit-optimize-contrast;
		margin: 0 auto;
	}

	.testimonial-content {
		display: block;
		position:relative;
		margin-bottom:1em;
	}

	.testimonial-content-quotes {
		quotes: "„" "“" "‚" "‘";
		&:before {
			content: open-quote;
			font-size: 2em;
			line-height: 0;
		}
		&:after {
			content: close-quote;
			font-size: 2em;
			line-height: 0;
		}
	}

	.testimonial-name {
		display: block;
		position:relative;
		font-size: 1.2em;
		margin-bottom:1em;
	}
}

.block-testimonials-slider-default {
	.testimonial-image {
		display: block;
		position: relative;
		width: 100%;
	}
	.testimonial-image img {
		display: block;
		position: relative;
		margin: 0 auto;
		max-width: 50%;
		height: auto;
	}
}