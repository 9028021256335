.block-products-title {
	display: block;
	position: relative;
	width: 100%;
}

.block-products-viewall {
	display: inline-block;

}

// .product-slick-slider-holder {
// 	display:block;
// 	position: relative;
// 	width:100%;
// 	height:230px;
// 	margin-bottom:20px;
// 	margin-top: 0px;

// 	ul {
// 		margin-top:0px;
// 	}
// }

// .product-slick-slider {
// 	list-style:none;
// 	opacity:0;
// }

.wl-productslide{

	.wl-productslide-labels {
		display: block;
	    position: absolute;
	    z-index: 200;
	    text-align: left;
	    left: 0;
	    top: 0;
	    width: 100%;
	    height: auto;
	}

	.wl-productslide-label {
		display: inline-block;
	    vertical-align: top;
	    line-height: 1em;
	    margin-right: 2px;
	}

// 	.wl-productslide-imageholder {
// 	/*display: inline-block;
//     position: relative;
//     width: auto;
//     margin: 0 auto;
//     padding-top: 30px;*/

//     display: block;
//     position: absolute;
//     width: auto;
//     margin: 0 auto;
//     margin-top: 30px;
//     bottom: 65px;
//     left: 50%;
//     -moz-transform: translateX(-50%);
// 	-webkit-transform: translateX(-50%);
// 	-o-transform: translateX(-50%);
// 	-ms-transform: translateX(-50%);
//     transform: translateX(-50%);
//     backface-visibility: hidden;
// }

	/* fold class specific css */

	a.theater .wl-productslide-imageholder img {
		-webkit-box-shadow: 0 0 2px rgba(0,0,0,.23);
		box-shadow: 0 0 2px rgba(0,0,0,.23);
	}

	a.theater .wl-productslide-imageholder img:nth-child(2) {
		width: 50%;
		position: absolute;
		transform-origin: left top;
	    left: 0;
	    top: 0;
	    -moz-transform: scaleX(0.85) skew(0deg,10deg);
		-webkit-transform: scaleX(0.85) skew(0deg,10deg);
		-o-transform: scaleX(0.85) skew(0deg,10deg);
		-ms-transform: scaleX(0.85) skew(0deg,10deg);
		transform: scaleX(0.85) skew(0deg,10deg);
	}

	a.theater .wl-productslide-imageholder img:last-child {
		width: 50%;
		position: absolute;
		transform-origin: right top;
		right: 0;
		top: 0;
	    -moz-transform: scaleX(0.85) skew(0deg,-10deg);
		-webkit-transform: scaleX(0.85) skew(0deg,-10deg);
		-o-transform: scaleX(0.85) skew(0deg,-10deg);
		-ms-transform: scaleX(0.85) skew(0deg,-10deg);
		transform: scaleX(0.85) skew(0deg,-10deg);
	}

	a.tweeluik .wl-productslide-imageholder:before {
		content:'';
		display:block;
		position:absolute;
		width:85%;
		height:30px;
		left: 0;
	    top: -30px;
		background-image: url('../../../css/folds/fold-slick-tweeluik.png');
		background-repeat:no-repeat;
		background-size: 100% 100%;
	}

	a.tweeluik .wl-productslide-imageholder.boven:before {
		content:'';
		display:block;
		position:absolute;
		width:30px;
		height:85%;
		right: -30px;
		left: auto;
	    top: 0;
		background-image: url('../../../css/folds/fold-slick-tweeluik-boven.png');
		background-repeat:no-repeat;
		background-size: 100% 100%;
	}

	a.drieluik .wl-productslide-imageholder:before {
		content:'';
		display:block;
		position:absolute;
		width:90%;
		height:30px;
		right: 0;
	    top: -30px;
		background-image: url('../../../css/folds/fold-slick-drieluik.png');
		background-repeat:no-repeat;
		background-size: 100% 100%;
	}

	a.vierluik .wl-productslide-imageholder:before {
		content:'';
		display:block;
		position:absolute;
		width:90%;
		height:30px;
		right: 0;
	    top: -30px;
		background-image: url('../../../css/folds/fold-slick-vierluik.png');
		background-repeat:no-repeat;
		background-size: 100% 100%;
	}
}

// .product-slick-slider .item {
	// display:inline-block;
	// padding-left: 15px;
	// padding-right: 15px;
	// height:230px;
// }

// .product-slick-slider .item-title {
// 	display: block;
//     position: absolute;
//     left: 0;
//     top: 170px;
//     width: 100%;
// 	padding-left: 10px;
// 	padding-right: 10px;
// 	text-align: center;
// }

// .product-slick-slider .item-imagewrapper {
// 	/*display: inline-block;
//     position: relative;
//     width: auto;
//     margin: 0 auto;
//     padding-top: 30px;*/

//     display: block;
//     position: absolute;
//     width: auto;
//     margin: 0 auto;
//     margin-top: 30px;
//     bottom: 65px;
//     left: 50%;
//     -moz-transform: translateX(-50%);
// 	-webkit-transform: translateX(-50%);
// 	-o-transform: translateX(-50%);
// 	-ms-transform: translateX(-50%);
//     transform: translateX(-50%);
//     backface-visibility: hidden;
// }

// .product-slick-slider .item a {
// 	border:none;
// 	outline:none;
// 	display: block;
//     position: relative;
//     width: 100%;
//     height: 100%;
//     overflow: visible;
// }

// .product-slick-slider .item img {
// 	display: inline-block;
// }

// .product-slick-slider .slick-next,
// .product-slick-slider .slick-prev {
//   height: 100%;
//   background-color:rgba(255,255,255,0.5);

// }
// .product-slick-slider .slick-next {
//   right:-6px !important;
// }

// .product-slick-slider .slick-prev {
//   left:-12px !important;
// }

// .product-slick-slider .slick-next:before,
// .product-slick-slider .slick-prev:before {
// 	font: normal normal normal 50px/1 FontAwesome;
// 	opacity: .8;
// }

// .product-slick-slider .slick-next:focus:before,
// .product-slick-slider .slick-prev:hover:before,
// .product-slick-slider .slick-prev:focus:before,
// .product-slick-slider .slick-prev:hover:before {
//     opacity: .8;
// }

// .product-slick-slider .slick-next:before {
// 	content: "\f105";
// }

// .product-slick-slider .slick-prev:before {
//     content: "\f104";
// }

