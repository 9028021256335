//  bootstrap nav-tabs override
.nav-tabs {
	border-bottom-color: #000000;
}

.nav-tabs .nav-link,
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link.active {
	color: #000000;
	border-color: #000000;
	margin-left: -1px;
}

.nav-tabs .nav-link {
	border-color: #000000;
	border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-weight: 500;
}

.nav-tabs .nav-link.active {
	background-color: #e4e4e4;
}

// account btn override
.btn {
	border-radius: 5px;
}
.btn.disabled {
	opacity: .30;
}

// account main submenu

#accountMenuInit:hover {
	font-size: inherit;
	text-decoration: none;
}

.logo-header-account-hassubmenu #logo-header-account-submenu {
	visibility: hidden;
    opacity: 0;
}

.logo-header-account-hassubmenu:hover #logo-header-account-submenu {
	visibility: visible;
    opacity: 1;
}

#logo-header-account-submenu {
	display: block;
	position: absolute;
	padding:0;
	margin:0;
	list-style: none;
	width: 220px;
	height: auto;
    left: 0;
    top: 100%;
    z-index: 600;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, .2), 0 4px 6px -2px rgba(0, 0, 0, .1);

	li {
		width: 100%;
		text-align: left;
	}

	li:hover {
		background-color: #cccccc;
	}

	li.separator {
		font-weight: 500;
		padding-right:10px;
		padding-left:10px;
		padding-top:7px;
		padding-bottom:7px;
		margin-bottom: 7px;
		border-bottom:1px #cccccc solid;
	}

	a {
		font-size: 14px !important;
		padding-right:10px !important;
		padding-left:10px !important;
		padding-top:4px !important;
		padding-bottom:4px !important;
		text-align: left !important;
	}

	li.selected {
		background-color: #cccccc;
	}
}

#account-main-menu {
	.drn-icon {
		display: none;
	}
	.h4 {
		border-bottom:1px currentColor solid;
		&:hover {
			font-size: initial;
		}
	}
	ul {
		display: block;
		position: relative;
		width: 100%;
		margin:0;
		padding:0;

		li {
			display: block;
			position: relative;
			width: 100%;
			font-size: 14px;

			a {
				display: block;
				position: relative;
				width: 100%;
				padding-top:5px;
				padding-bottom: 5px;
				margin-bottom: 3px;
				color:initial;
				text-decoration: none;
				padding-left: 10px;
				padding-right: 10px;
			}

			a:hover {
				background-color: #e4e4e4;
			}
		}
	}
	.selected {
		background-color: #e4e4e4;
		// padding-left: 10px;
		// padding-right: 10px;
		a:hover {
			border-bottom: none;
		}
	}
}

// account main holder

// #account-main-content,
// #account-main-content p,
// #account-main-content a {
// 	font-size: 14px; // settings item
// }

#account-main-content {
	.container-xxxl {
		padding-right: 0;
		padding-left: 0;
	}
}

// account content blocks

.account-content-block {
	background-color: #FFFFFF;
	border: #e4e4e4 1px solid;
	box-shadow: 0 .2rem .5rem rgba(0, 0, 0, .15) !important;

	-webkit-transition: all 200ms ease-in-out;
	-moz-transition: all 200ms ease-in-out;
	-ms-transition: all 200ms ease-in-out;
	-o-transition: all 200ms ease-in-out;
	transition: all 200ms ease-in-out;

	&:hover {
		box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
	}
}

.account-content-block-container {
	display: block;
	position: relative;
	width: 100%;
	padding:0;
	margin:0;
	list-style: none;
}

// account ordered products

.account-content-block-imgholder {
	position: relative;
	height: 100%;
}

.account_order_status {
	display: block;
    position: relative;
    font-weight: bold;
	color: #c3aba2;
}

.account_order_senddate {
	display: block;
    position: relative;
    font-weight: bold;
	color: #cf6c6c;
}


.account-content-block-specs,
.account-content-block-info {
	border-top: 1px solid #e4e4e4;
}
.account_orders_gridholder {
	display: block;
	position: relative;
	background-color: #FFFFFF;
}
.account_orders_grid_1,
.account_orders_grid_2,
.account_orders_grid_3,
.account_orders_grid_4 {
	display: grid;
	position: relative;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(2, 1fr);
	grid-column-gap: 6px;
	grid-row-gap: 6px;
	&::before {
	    content: '';
	    width: 100%;
	    padding-bottom: 100%;
	    grid-row: 1 / 1;
	    grid-column: 1 / 1;
	}
}

.account_orders_grid_1_1,
.account_orders_grid_2_1,
.account_orders_grid_2_2,
.account_orders_grid_3_1,
.account_orders_grid_3_2,
.account_orders_grid_3_3,
.account_orders_grid_4_1,
.account_orders_grid_4_2,
.account_orders_grid_4_3,
.account_orders_grid_4_4 {
	display: block;
	position: relative;
	background-color: #FFFFFF;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center center;
}

.account_orders_grid_1_1 {
	grid-area: 1 / 1 / 3 / 3;
	
}

.account_orders_grid_2_1 {
	grid-area: 1 / 1 / 3 / 2;
}
.account_orders_grid_2_2 {
	grid-area: 1 / 2 / 3 / 3;
}

.account_orders_grid_3_1 {
	grid-area: 1 / 1 / 3 / 2;
}
.account_orders_grid_3_2 {
	grid-area: 1 / 2 / 2 / 3;
}
.account_orders_grid_3_3 {
	grid-area: 2 / 2 / 3 / 3;
}

.account_orders_grid_4_1 {
	grid-area: 1 / 1 / 2 / 2;
}
.account_orders_grid_4_2 {
	grid-area: 2 / 1 / 3 / 2;
}
.account_orders_grid_4_3 {
	grid-area: 1 / 2 / 2 / 3;
}
.account_orders_grid_4_4 {
	grid-area: 2 / 2 / 3 / 3;
}


// account saved cards

#account_saved_cards {

	.account_saved_card_copy {
		display: block;
		position: absolute;
		left:0;
		top:0;
		width: 100%;
		height: 100%;
		background-color: rgba(255,255,255,0.8);
		font-size: calc(100% + 2px);
	}

	.account_saved_card_datafield span {
		display: inline-block;
		position: relative;
		width: 20px;
	}

	.saved_card_thumb {
		width:100%;
		height:100%;
		max-width: 250px;
		max-height: 250px;
	}
}

// toolbutton submenu

.account_toolbutton_submenu {
	visibility: hidden;
    opacity: 0;
}

.account_toolbutton_submenu {
	display: block;
	position: absolute;
	padding:0;
	margin:0;
	list-style: none;
	width: 100%;
	height: auto;
    left: 0;
    top: 100%;
    z-index: 10;
    background-color: #FFFFFF;
    border: 1px #e4e4e4 solid;
    box-shadow: 0 .2rem .5rem rgba(0, 0, 0, .15) !important;
}

.account_toolbutton_submenu.wishlist {
	width: 80%;
	min-width: 180px;
	left: 10%;
}

.account_toolbutton {
	display: block;
	position: relative;
	width: 100%;
	list-style: none;
	padding:0;
	margin:0;
	font-size: 12px;
    line-height: 1.2em;

	.account_toolbutton_list {
		border-top: 1px #e4e4e4 solid;
	}

	.account_toolbutton_list .drn-icon-like-checked {
		color: var(--primary-button-color-01);
	}

	li {
		display: block;
		position: relative;
		width: 100%;
	}

	li.account_toolbutton_list:first-child {
		padding-left:15px;
	}

	li a,
	li p {
		display: block;
	    position: relative;
	    text-decoration: none;
	    color:#000000;
	}

	li a:hover,
	li p:hover {
		color:var(--primary-button-color-01);
	}
}

.account_toolbutton_list:hover .account_toolbutton_submenu {
	visibility: visible;
    opacity: 1;
}

// account wishlist

.account_wishlistitem_imgholder,
.account_imageitem_imgholder {
	display: block;
	position: relative;
	width: 100%;
	height: 15vw;
	min-height: 190px;
	img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}

// account labels

#account-label-design {
	background-color: #e6e6e6;
}

#addressbook-label-choice-image {
	display: block;
	position: relative;
	width: 50%;
	min-width: 260px;
	border: #e4e4e4 1px solid;
	margin: 0 auto;
	background-color: #FFFFFF;
	img {
		display: block;
		position: relative;
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}

#addressbook-label-example-address {
	display: block;
	position: absolute;
	width: 100%;
	top:50%;
	color:#000000;
	line-height: 1.4em;
	-moz-transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	font-size: 15px;
}

.addressbook-label-designselector {
	width:80%;
}

.addressbook-label-designselector-holder {
	background-color: #FFFFFF;
	border-radius: 5px;
	cursor: pointer;
}

#addressbookLabelImages {
	height: 200px;
    overflow-y: auto;
    background-color: #FFFFFF;

	.addressbook-label-item-image {
		display: block;
		position: relative;
		width: 100%;
		border: #cccccc 1px solid;
		box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, .2);
	}
	.addressbook-label-item-image img {
		display: block;
		position: relative;
		width: 100%;
		height: 100%;
		object-fit: contain;
		background-color: #FFFFFF;
	}
	.addressbook-label-item-info {
		display: block;
		position: relative;
		width: 100%;
	}

}

#addresbookFontSelectList {
	display: grid;
	grid-template-columns: auto auto auto auto;
	height: 200px;
    padding: 15px;
    overflow-y: auto;
    background-color: #FFFFFF;

    .addressbookFont {
    	cursor: pointer;
		height:35px;
		width:100%;
		font-size:20px;
		color:#000000;
    }
}

// account footer

#text-footer {
	display: block;
    position: relative;
    padding: .5em;
    text-align: center;
}

// responsive

// ---- mediaqueries max width

@media (max-width: 1399px){
    
}

@media (max-width: 1199px){
    
}

@media (max-width: 991px){
    #logo-header-account-submenu {
	    left: auto;
    	right: 0;
	}
}

@media (max-width: 767px){
    #account-main-menu {
    	display: none;
		.drn-icon {
			display: inline-block;
		}
		ul {
			display: none;
		}
	}
}

@media (max-width: 575px){
    .designs-tools {
		justify-content: flex-end !important;
		flex-direction: row !important;
	}

	.account-content-block-imgholder {
		text-align: center;
	}

	.account_toolbutton li.account_toolbutton_list:first-child {
		padding-left:0px;
	}
	#addresbookFontSelectList {
		grid-template-columns: auto auto;
	}
}

// ---- mediaqueries min width

@media (min-width: 576px) {

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {

}