/* ---- vertical filter ---- */

.category-filters-block,
#category-filters-vertical {
	width: 90%;
}

#category-filters-block-slider {
	display: block;
	position: relative;
	width: 100%;
}

#category-filters-mobile-close {
	display: none;
}

#category-filters-block-slider-backdrop {
	display: none;
}

.category-filters-vertical-maintitle {
	display: block;
	position: relative;
	span {
		display: inline-block;
		float:right;
		cursor: pointer;
	}
}

.category-filters-vertical-block {
	display: block;
	position: relative;
}

.category-filters-vertical-links {
	display: block;
	position: relative;
	width: 100%;
	padding:0;
	margin:0;
	list-style: none;

	li {
		display: block;
		position: relative;
		width: 100%;
		padding-left:1em;
	}

	a {
		display: block;
		position: relative;
		width: 100%;
		text-decoration: none;
		padding-top:1em;
		padding-bottom:1em;
		padding-left:1em;
		padding-right:1em;
	}
}

.category-filters-vertical-items {
	padding-left:1em;
}

.category-filters-vertical-item {
	display: block;
	position: relative;
	width: 100%;

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	label {
		display: block;
		position: relative;
		width: 100%;
		text-decoration: none;
		padding-top:1em;
		padding-bottom:1em;
		padding-left:1em;
		padding-right:1em;
		cursor: pointer;
	}

	.category-filters-vertical-result {
		display: inline-block;
		float:right;
	}
}

.category-filters-vertical-item-separator {
	position: relative;
	padding-top:1em;
	padding-bottom:1em;
	padding-left: calc(1.3em + 20px);
	cursor: pointer;
	color: var(--primary-01);

	&:before {
		content: "+";
	    position: absolute;
	    display: block;
	    left: 0;
	    width: 20px;
	    height: 20px;
	    font-size: 1.6em;
	    padding-left: 0.6em;
	}
}

.category-filters-vertical-item-separator.open {
	&:before {
		content:'-';
	}
}

.category-filters-vertical-item-content {
	//display: none;
	height: 0;
	overflow: hidden;
}

/* Create a custom checkbox */
.category-filters-vertical-checkmark {
	display: inline-block;
	position: relative;
	height: 20px;
	width: 20px;
	margin-bottom: -0.35em;
	margin-right: 0.3em;
}

/* On mouse-over, add a grey background color */
.category-filters-vertical-item:hover input ~ .category-filters-vertical-checkmark {
	// background-color: #ccc;
	// border: 1px #ccc solid;
}

/* When the checkbox is checked, add a blue background */
.category-filters-vertical-item input:checked ~ .category-filters-vertical-checkmark {
	// background-color: #2196F3;
	// border: 1px #2196F3 solid;
}

/* Create the checkmark/indicator (hidden when not checked) */
.category-filters-vertical-checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.category-filters-vertical-item input:checked ~ .category-filters-vertical-checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.category-filters-vertical-item .category-filters-vertical-checkmark:after {
	left: 7px;
	top: 3px;
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.category-filters-vertical-colorswatch {
	display: inline-block;
	width: 20px;
    height: 20px;
    margin-bottom: -0.35em;
}

@media (max-width: 767px){

	#category-filters-block-slider-backdrop {
		display: block;
		position: fixed;
		left:-100%;
		top:0;
		z-index: 600;
		width: 100%;
		height: 100%;
		opacity: 0;
		background-color: rgba(0,0,0,0.3);

		-webkit-transition: opacity 200ms ease-in-out;
		-moz-transition: opacity 200ms ease-in-out;
		-ms-transition: opacity 200ms ease-in-out;
		-o-transition: opacity 200ms ease-in-out;
		transition: opacity 200ms ease-in-out;
	}

	#category-filters-block-slider-backdrop.open {
		left:0;
		opacity: 1;
	}

	#category-filters-block-slider {
		display: flex;
		flex-direction: column;
		position: absolute;
		background-color: #FFFFFF;
		left:0;
		top:0;
		z-index: 1500;
		left: calc(-80vw - 15px);
		width: 80vw;
		padding:15px;

		-webkit-box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 15px -3px, rgba(0, 0, 0, 0.1) 0px 4px 6px -2px;
		box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 15px -3px, rgba(0, 0, 0, 0.1) 0px 4px 6px -2px;

		-webkit-transition: left 200ms ease-in-out;
		-moz-transition: left 200ms ease-in-out;
		-ms-transition: left 200ms ease-in-out;
		-o-transition: left 200ms ease-in-out;
		transition: left 200ms ease-in-out;
		
		-moz-transform-origin: left;
	    -webkit-transform-origin: left;
		-o-transform-origin: left;
		-ms-transform-origin: left;
	    transform-origin: left;
	}

	#category-filters-mobile-close {
		display: inline-block;
		margin-left:1em;
	}

	#category-filters-block-slider.open {
		left: 0;
	}

	.category-filters-block,
	#category-filters-vertical {
		width: 100%;
	}

	.category-filters-block {
		order:1;
	}

	#category-filters-vertical {
		order:0
	}

}