.imageblock {
	display: block;
	position: relative;
	overflow: hidden;

	a, a:hover {
		text-decoration: underline;
		cursor: pointer;
	}

	img {
		vertical-align: middle;
		position: absolute;
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
}

.imageblock-default {
	.imageblock-title {
		display: block;
		position:absolute;
		text-align: center;
		z-index: 5;
		bottom:0;
		left:0;
		width: 100%;
		// height: fit-content;
		padding:0.6em;
		background-color: rgba(255, 255, 255, 0.8);
	}
	.imageblock-title-text {

	}
}

.imageblock-button {
	.imageblock-title {
		display: inline-block;
		position: absolute;
		text-align: center;
		z-index: 5;
		bottom: 0;
		left: 50%;
		width: calc(100% - 2.8em);
		// height: fit-content;
		-moz-transform: translateX(-50%);
		-webkit-transform: translateX(-50%);
		-o-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);
		padding-top: 0.6em;
		padding-bottom: 0.6em;
		padding-left: 1.4em;
		padding-right: 1.4em;
		margin-bottom: 1.4em;
		background-color: rgba(255, 255, 255, 0.8);
	}
	.imageblock-title-text {

	}
}