#cartcontent-overview-container {
    // font-size: 12px;
    // line-height: 1.5em;

    .disabled {
        display: none;
    }
}

// disable / hide cart elements

.radio-product-option-holder,
#cartcontent-product-options,
#cartcontent-envelopes-quantity,
#cartcontent-envelopes,
#cartcontent-envelopes-options,
#cartcontent-vgpsluitstickers-quantity,
#cartcontent-vgpsluitstickers,
#cartcontent-vgpsluitsticker-options,
#cartcontent-extras-continue,
#cartcontent-overview-extras-continue,
#cartcontent-extras-options,
#cartcontent-overview-tocart,
#cartcontent-tocart {
    &.disabled {
        display: none;
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: none;
    }
}

.disabledProductoption {
    display: none;
    opacity: 0.5;
    cursor:not-allowed;
    pointer-events: none;
}



// end disable / hide cart elements

#cartAjaxLoader {
    display: block;
    position: fixed;
    z-index: 9999999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.6);

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -30px;  
        margin-left: -30px;
        width: 50px;
        height: 50px;
        border-radius: 50px;
        border: 5px solid grey;
        border-top-color: black;
        animation: loading 2s linear infinite;
    }

    @keyframes loading {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}

#logo-header-cart:hover #logo-header-cart-overview {
    //display: block !important;
}

#logo-header-cart-overview {
    //display: block;
    position: absolute;
    z-index: 500;
    right: 0;
    top: 0;
    padding-top: 35px;
    width: 200px;
    display: none;
    .logo-header-cart-overview-container {
        display: block;
        position: relative;
        width: 100%;
        min-height: 50px;
        background-color: #FFFFFF;
        -webkit-box-shadow: 0 3px 4px 0 rgba(0,0,0,0.2);
        box-shadow: 0 3px 4px 0 rgba(0,0,0,0.2);
        border: 1px #e4e4e4 solid;
    }
}

// product radio options

.radio-product-option-label {
    display: block;
    position: absolute;
    z-index: 5;
    left:10px;
    top:10px;
    background-color: var(--cartstep-stepsactive-background);
    color: var(--cartstep-stepsactive-color);
    padding-left: 0.5em;
    padding-right: 0.5em;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    text-transform: capitalize;
    -webkit-box-shadow: 0 3px 4px 0 rgba(0,0,0,0.2);
    box-shadow: 0 3px 4px 0 rgba(0,0,0,0.2);
}
.radio-product-option {
    display: block;
    position: relative;
    width: 100%;
    padding-top: 106%;
    height: 0;
}
.radio-product-option > input {
    display: none;
}
.radio-product-option > .radio-content {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 2px solid #f3f3f3;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    line-height: 1.2em;
    font-size: 14px;
}
.radio-product-option > input + .radio-content {
    cursor: pointer;
    font-size: 12px;
}
.radio-product-option > input:disabled + .radio-content {
    opacity: 0.2;
    cursor:not-allowed;
    pointer-events: none;
}
.radio-product-option > input:checked + .radio-content {
    border: 2px solid #9fc58a;
    -webkit-box-shadow: 0 3px 4px 0 rgba(0,0,0,0.2);
    box-shadow: 0 3px 4px 0 rgba(0,0,0,0.2);
}
.radio-product-option > input:checked + .radio-content:after {
    content:'';
    display: block;
    position: absolute;
    right:-15px;
    top:-15px;
    width: 30px;
    height: 30px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background-color: #9fc58a;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJMYWFnXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDI1IDE5Ij48cGF0aCBkPSJtMjQuNTMuNDljLS4zLS4zMi0uNzktLjMyLTEuMDksMGwtMTUuMzYsMTYuMzNMMS41Niw5Ljg4Yy0uMy0uMzItLjc5LS4zMi0xLjA5LDAtLjI5LjMxLS4yOS44LDAsMS4xMWw3LjA3LDcuNTFjLjE1LjE2LjM0LjI0LjU0LjI0cy40LS4wOC41NC0uMjRMMjQuNTMsMS42Yy4yOS0uMzEuMjktLjgsMC0xLjExWiIgZmlsbD0iI2ZmZiIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9Ii41Ii8+PC9zdmc+");
    background-position: center 44%;
    background-repeat: no-repeat;
    background-size: 58%;
}

.radio-product-option > .radio-content .radio-swatch {
    display: block;
    position: relative;
    width: 80%;
    height: 0px;
    padding-bottom: 60%;
    margin: 0 auto;
    margin-bottom:10px;
    margin-top: 10px;
}

.radio-swatch-title {
    display: block;
    padding-top: 0.5rem;

    span {
        display: block;
    }
}

.radio-swatch-subtitle {
    display: block;
    padding-top: 0.5rem;
}

.radio-product-option > .radio-content .radio-swatch.hasimage {
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}

.radio-product-option > .radio-content.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.radio-content-warning {
    display: block;
    position: absolute;
    width: 100%;
    z-index: 10;
    left: 0;
    bottom: 0;
    text-align: center;
    font-size: small;
    line-height: 1.1em;
    padding: 0.4em;
    background-color: #990000;
    color: #FFFFFF;
}
.btn-cart-continue-text {
    padding-right: 2em;
}

.btn.w-100 {
    display: flex;
    flex-wrap: wrap;
    align-items: end;
}

.btn.w-100 .btn-cart-continue-text.text-truncate {
    display: inline-block;
    padding-right: 0em;
    width: calc(100% - 2em);
}

// extras
.cartcontent-extras-holder {
    border:1px #F4F4F4 solid;
    padding:1vw;

    &.incart {
        border-color:#9fc58a;
    }

    input[type='number'] {
        width: 3em;
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button, {
        -webkit-appearance: none;
    }

    input[type='number'] {
        -moz-appearance: textfield;
    }

}

.btn.incart {
    border-color:#9fc58a;
    color:#9fc58a !important;
    .btn-cart-continue-text {
        padding-right: 0;
    }
    .drn-icon {
        display: none;
    }
}

// overview

.cartcontent-overview-product-holder {
    border:1px #F4F4F4 solid;
    padding:1vw;
}

.cartcontent-overview {
    display: block;
    position: relative;
    background-color: #F4F4F440;
    padding-left:2vw;
    padding-right:2vw;
    padding-top:1.5vw;
    padding-bottom:1.5vw;
}

.cartcontent-overview-largeimage {
    display: block;
    position: relative;
    width: 100%;
    cursor: pointer;
}

#cartcontent-overview-total {
    border-top:1px #000000 solid;
}

.cartOverviewAmount-price-free {
    color:#9fc58a;
    text-transform: uppercase;
}

#cart-backto-editor {
    display: block;
    position: absolute;
}

// editable invoice and delivery form
#cartEditInvoiceAddress,
#cartEditDeliveryAddress {
    .form-invoice-editable.form-not-editable,
    .form-delivery-editable.form-not-editable {
        margin-bottom:10px !important;
        label {
            display: none;
        }
        .form-control:disabled,
        .form-select:disabled {
            background-color: transparent;
        }
        .form-select:disabled {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          background-image: none;
        }
    }
}

// radio payment options
.radio-payment-option {
    display: block;
    position: relative;
    width: 100%;
    padding-top: 75%;
    height: 0;
}
.radio-payment-option > input {
    display: none;
}
.radio-payment-option > .radio-content {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 2px solid #f3f3f3;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    line-height: 1.2em;
    font-size: 14px;
}
.radio-payment-option > input + .radio-content {
    cursor: pointer;
    font-size: 12px;
}

.radio-payment-option > input:checked + .radio-content {
    border: 2px solid #9fc58a;
    -webkit-box-shadow: 0 3px 4px 0 rgba(0,0,0,0.2);
    box-shadow: 0 3px 4px 0 rgba(0,0,0,0.2);
}
.radio-payment-option > input:checked + .radio-content:after {
    content:'';
    display: block;
    position: absolute;
    right:-15px;
    top:-15px;
    width: 30px;
    height: 30px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background-color: #9fc58a;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJMYWFnXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDI1IDE5Ij48cGF0aCBkPSJtMjQuNTMuNDljLS4zLS4zMi0uNzktLjMyLTEuMDksMGwtMTUuMzYsMTYuMzNMMS41Niw5Ljg4Yy0uMy0uMzItLjc5LS4zMi0xLjA5LDAtLjI5LjMxLS4yOS44LDAsMS4xMWw3LjA3LDcuNTFjLjE1LjE2LjM0LjI0LjU0LjI0cy40LS4wOC41NC0uMjRMMjQuNTMsMS42Yy4yOS0uMzEuMjktLjgsMC0xLjExWiIgZmlsbD0iI2ZmZiIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9Ii41Ii8+PC9zdmc+");
    background-position: center 44%;
    background-repeat: no-repeat;
    background-size: 58%;
}

.radio-payment-option > .radio-content .radio-swatch {
    display: block;
    position: relative;
    width: 80%;
    height: 100%;
    margin: 0 auto;

    img {
        display: block;
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

// ---- dynamic quantity select
.quantity_select_pulldown_holder {
    display: block;
    position: relative;
    width: 100px;

    .quantity_select_pulldown {
        display: block;
        position: absolute;
        z-index: 100;
        border: 1px #e8e3e3 solid;
        background-color: #FFFFFF;
        height: 130px;
        width:100%;
        overflow-y: auto;
        -webkit-box-shadow: 0 3px 4px 0 rgba(0,0,0,0.2);
        box-shadow: 0 3px 4px 0 rgba(0,0,0,0.2);

        ul {
            display: block;
            position: relative;
            width: 100%;
            padding:0;
            margin: 0;
            list-style: none;
        }

        li {
            display: block;
            position: relative;
            padding-left:0.5em;
            padding-right:0.2em;
            padding-top:0.2em;
            padding-bottom:0.2em;
            border-bottom:1px #f4f4f4 solid;
            cursor: pointer;
        }

        li:hover {
            background-color: #f4f4f4;
        }
    }
}

// ---- mediaqueries max width

@media (max-width: 1399px){
    
}

@media (max-width: 1199px){
    
}

@media (max-width: 991px){
    #cart-backto-editor {
        position: relative;
        text-align: center;
    }
}

@media (max-width: 767px){
    
}

@media (max-width: 575px){
    
}

// ---- mediaqueries min width

@media (min-width: 576px) {

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    #isSticky {
        position: -webkit-sticky;
        position: sticky;
        top:var(--headerOffset); // --headerOffset is generated by Checkout.js
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {

}

