* {
	min-height: 0;
	min-width: 0;
}

body.noscroll {
	overflow: hidden;
	padding-right: 0px;
}

.body-wrapper,
#main {
	display: block;
	position: relative;
	width: 100%;
	height: auto;
	overflow: hidden; // does not work width sticky elements
}

hr {
	display: block;
    width: 100%;
}

.container-xxxl {
	width: 100%;
	padding-right: 3rem;
	padding-left: 3rem;
	margin-right: auto;
	margin-left: auto;
}

/* ---- bootstrap overwrites ---- */

.modal {
	z-index: 100000 !important;
}

.modal-backdrop {
	z-index: 99999 !important;
}

div.img-fluid img,
.img-fluid {
	display: block;
	width: 100% !important;
	max-width: none;
	height: auto !important;
	image-rendering: -webkit-optimize-contrast;
}

.ratio-3x4 {
	// "1x1": 100%,
	// "4x3": calc(3 / 4 * 100%),
	// "16x9": calc(9 / 16 * 100%),
	// "21x9": calc(9 / 21 * 100%)
    --bs-aspect-ratio: calc(4 / 3 * 100%);
}

.ratio iframe {
	background-color: #000000;
}

.accordion-button:focus {
	box-shadow: none;
}

@media (min-width: 1600px) {
	.container-xxxl {
		max-width: 1600px;
	}
}

@media (max-width: 767px){
	.container {
	    padding-right: 5px;
	    padding-left: 5px;

	    .row {
	    	margin-right: 0px;
	    	margin-left: 0px;
	    }
	}

	.container-fluid {
	    padding-right: 0px;
	    padding-left: 0px;

	    .row {
	    	margin-right: 0px;
	    	margin-left: 0px;
	    }
	}

	.container-xxxl {
		padding-right: 5px;
	    padding-left: 5px;

	    .row {
	    	margin-right: 0px;
	    	margin-left: 0px;
	    }
	}

	.col-12 {
		// niet overal een margin...
		// margin-bottom: 2em;
	}

	// [id^="gridid_"] .block-content {
	// 	margin-top:16px !important;
	// }

	// div[not='.slick-slide'] [id^="blockid_"] {
	// 	padding-left:10px !important;
	// 	padding-right:10px !important;
	// }

	// mobile padding / margin overwrites

	.block-content .block-content-text,
	.block-content-holder {
	    padding-top: 15px !important;
	    padding-bottom: 15px !important;
	    padding-left: 15px !important;
	    padding-right: 15px !important;
	}

	div[id^="blockid_"].col-12:not(:last-child) {
		margin-bottom:15px !important;
	}

	// fullwidht columns
	.container-fluid div[id^="blockid_"].col-12 {
		width: 100% !important;
		margin-left: 0 !important;
		margin-right: 0 !important;
	}

	// section[id^="gridid_"] {
	// 	margin-bottom:30px !important;
	// }

	// div[not='.slick-slide'] [id^="blockid_"] .block-content {
	// 	padding:15px !important;
	// }
}