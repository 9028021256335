.wl-uspslider-3 {
	width: 100%;
}

.wl-uspslider {
    position: relative;
    height: 25px;
    width: 100%;
    padding:0;
    margin:0;
    list-style: none;
}

@media (min-width: 768px) {
	.wl-uspslider {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-around;
		align-items: center;

		li {
			margin: 0.25rem 0.625rem;
			text-align: left;
			align-items: center;
			justify-content: center;
			line-height: 1;
			text-align: center;
		}
	}
}

@media (max-width: 767px){
	.wl-uspslider {
		display: block;
		overflow: hidden;

		li {
			align-items: center;
		    display: flex;
		    height: 25px;
		    justify-content: center;
		    margin: 0;
		    line-height: 1;
		    padding: 0;
		    position: absolute;
		    text-align: center;
		    width: 100%;
		}

		li:first-child {
			-webkit-animation: uspOneOfThree 10s ease-in-out infinite;
    		animation: uspOneOfThree 10s ease-in-out infinite;
		}

		li:nth-child(2) {
		    -webkit-animation: uspTwoOfThree 10s ease-in-out infinite;
		    animation: uspTwoOfThree 10s ease-in-out infinite;
		}

		li:nth-child(3) {
		    -webkit-animation: uspThreeOfThree 10s ease-in-out infinite;
		    animation: uspThreeOfThree 10s ease-in-out infinite;
		}
	}
}

@-webkit-keyframes uspOneOfThree {
    97% {
        opacity: 0;
        top: 42px
    }

    0%, 27%, to {
        opacity: 1;
        top: 0
    }

    30% {
        opacity: 0;
        top: -42px
    }
}

@keyframes uspOneOfThree {
    97% {
        opacity: 0;
        top: 42px
    }

    0%, 27%, to {
        opacity: 1;
        top: 0
    }

    30% {
        opacity: 0;
        top: -42px
    }
}

@-webkit-keyframes uspTwoOfThree {

    0%, 30% {
        opacity: 0;
        top: 42px
    }

    33%, 60% {
        opacity: 1;
        top: 0
    }

    63%, to {
        opacity: 0;
        top: -42px
    }
}

@keyframes uspTwoOfThree {

    0%, 30% {
        opacity: 0;
        top: 42px
    }

    33%, 60% {
        opacity: 1;
        top: 0
    }

    63%, to {
        opacity: 0;
        top: -42px
    }
}

@-webkit-keyframes uspThreeOfThree {

    0%, 63% {
        opacity: 0;
        top: 42px
    }

    66%, 94% {
        opacity: 1;
        top: 0
    }

    97%, to {
        opacity: 0;
        top: -42px
    }
}

@keyframes uspThreeOfThree {

    0%, 63% {
        opacity: 0;
        top: 42px
    }

    66%, 94% {
        opacity: 1;
        top: 0
    }

    97%, to {
        opacity: 0;
        top: -42px
    }
}