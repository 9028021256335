:root {
	// rightbottom align
	--wp-rightbottom-right: 8px; // todo: make dynamic
	--wp-rightbottom-bottom: 65px; // todo: make dynamic
	--wp-rightbottom-bottomstart: 0px; // todo: make dynamic
	--wp-rightbottom-border-left-bottom: 0px; // todo: make dynam
	--wp-rightbottom-border-right-bottom: 20px; // todo: make dynam
	// leftbottom align
	--wp-leftbottom-left: 15px; // todo: make dynamic
	--wp-leftbottom-bottom: 15px; // todo: make dynamic
	--wp-leftbottom-bottomstart: 0px; // todo: make dynamic
	--wp-leftbottom-border-left-bottom: 20px; // todo: make dynamic
	--wp-leftbottom-border-right-bottom: 0px; // todo: make dynamic
	// other
	--wp-01-padding: 5px; // todo: make dynamic
	--wp-02-padding: 18px; // todo: make dynamic
	--wp-03-padding: 12px; // todo: make dynamic
	// colors
	--wp-green: #0b9f17; // todo: make dynamic
}

.whatsappHolder {
	display: block;
	position: fixed;
	overflow: hidden;
	cursor: pointer;
	z-index: 1400;
	width: 60px;
	height: 60px;
	background-image: url('/images/icons/site-default/whatsapp-green.svg');
	background-size: contain;
	background-repeat: no-repeat;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	-webkit-box-shadow: 0 0 8px 0 rgba(0,0,0,0.3);
	box-shadow: 0 0 8px 0 rgba(0,0,0,0.3);

	&.right-bottom {
		right: var(--wp-rightbottom-right);
    	bottom: var(--wp-rightbottom-bottom);
	}

	&.left-bottom {
		left: var(--wp-leftbottom-left);
		bottom: var(--wp-leftbottom-bottom);
	}
}

// default layout
.whatsappMessage.layout_01 {
	display: block;
	opacity: 0;
	visibility : hidden;
	position: fixed;
	overflow: hidden;
	z-index: 1401;
	width: 200px;
	height: auto;
	padding:var(--wp-01-padding);
	background-color: #FFFFFF;

	&.right-bottom {
		right:calc(var(--wp-rightbottom-right) + 10px);
		bottom:var(--wp-rightbottom-bottomstart);
		-webkit-border-radius: 0 0 var(--wp-rightbottom-border-right-bottom) var(--wp-rightbottom-border-left-bottom);
		border-radius: 0 0 var(--wp-rightbottom-border-right-bottom) var(--wp-rightbottom-border-left-bottom);
	}

	&.left-bottom {
		left:calc(var(--wp-leftbottom-left) + 10px);
		bottom:var(--wp-leftbottom-bottomstart);
		-webkit-border-radius: 0 0 var(--wp-leftbottom-border-right-bottom) var(--wp-leftbottom-border-left-bottom);
		border-radius: 0 0 var(--wp-leftbottom-border-right-bottom) var(--wp-leftbottom-border-left-bottom);
	}


	-webkit-box-shadow: 0 0 8px 0 rgba(0,0,0,0.3);
	box-shadow: 0 0 8px 0 rgba(0,0,0,0.3);

	-webkit-transition: all 300ms ease-in-out;
	-moz-transition: all 300ms ease-in-out;
	-ms-transition: all 300ms ease-in-out;
	-o-transition: all 300ms ease-in-out;
	transition: all 300ms ease-in-out;

	&.active {
		visibility : visible;
		opacity: 1;
	}

	&.right-bottom.active {
		bottom:calc(var(--wp-rightbottom-bottom) + 10px);
	}

	&.left-bottom.active {
		bottom:calc(var(--wp-leftbottom-bottom) + 10px);
	}

	img {
		display: block;
		position: relative;
		width: 100%;
		height: auto;
	}

	p {
		padding-top:1em;
		line-height: 1.4em;
	}

	#whatsappUrlCta {
		text-decoration: none;
	}

	#whatsappUrlCta:hover {
		font-weight: normal;
	}

	.whatsappClose {
		display: block;
		position:relative;
		text-align: right;
	}

	.whatsappClose:hover {
		font-weight: normal;
	}

	#whatsappHeader,
	#whatsappFooter {
		display: none;
	}
}

// chat layout
.whatsappMessage.layout_02 {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
  	flex-wrap: wrap;
	opacity: 0;
	visibility : hidden;
	position: fixed;
	z-index: 1401;
	width: 84%;
	min-width: 270px;
	max-width: 320px;
	height: auto;
	padding-top: 70px;
	padding-left:var(--wp-02-padding);
	padding-right:var(--wp-02-padding);
	padding-bottom:var(--wp-02-padding);
	background-color: #FFFFFF;
	border:1px #000000 solid;
	left:50%;
	top:50%;
	-webkit-border-radius: 10px;
	border-radius: 10px;
	-moz-transform: translateX(-50%) translateY(-50%);
	-webkit-transform: translateX(-50%) translateY(-50%);
	-o-transform: translateX(-50%) translateY(-50%);
	-ms-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);

	-webkit-transition: all 300ms ease-in-out;
	-moz-transition: all 300ms ease-in-out;
	-ms-transition: all 300ms ease-in-out;
	-o-transition: all 300ms ease-in-out;
	transition: all 300ms ease-in-out;

	-webkit-box-shadow: 0 0 8px 0 rgba(0,0,0,0.3);
	box-shadow: 0 0 8px 0 rgba(0,0,0,0.3);

	&.active {
		visibility : visible;
		opacity: 1;
	}

	#whatsappFooter {
		display: none;
	}

	#whatsappHeader {
		content:'';
		display: block;
		position:absolute;
		left:0;
		top:0;
		width: 100%;
		height: 60px;
		background-color: var(--wp-green);
		-webkit-border-radius: 9px 9px 0 0;
		border-radius: 9px 9px 0 0;
		background-image: url('/images/icons/site-default/whatsapp-green.svg');
	    background-size: 40px 40px;
	    background-repeat: no-repeat;
	    background-position: 6px 50%;

	    &:before {
	    	content:'online';
	    	display: block;
	    	position:absolute;
	    	color:#FFFFFF;
	    	left:50%;
	    	line-height: 0;
	    	bottom: 10px;
	    	font-size: 12px;
	    	-moz-transform: translateX(-50%);
			-webkit-transform: translateX(-50%);
			-o-transform: translateX(-50%);
			-ms-transform: translateX(-50%);
			transform: translateX(-50%);
	    }

	    .whatsappLogo {
			display: block;
		    padding-top: 6px;
		    width: 59%;
		    height: 40px;
		    margin: 0 auto;
		    object-fit: contain;
	    }
	}

	.whatsappText {
		order:0;
		flex: 1 1 100%;
		display: block;
		width: calc(100% - var(--wp-02-padding));
		margin-left: calc(var(--wp-02-padding));
		background-color: #f0eae4;
		-webkit-border-radius: 10px;
		border-radius: 10px;
		padding-top: 0.2em;
		padding-bottom: 0.2em;
		padding-left: 1.4em;
		padding-right: 1.4em;
		&:before {
			content:'';
			display: block;
			position:absolute;
			left: calc(var(--wp-02-padding));
    		top: 66px;
			width: 0;
			height: 0;
			border-style: solid;
			border-right: 12px solid transparent;
			border-left: 12px solid transparent;
			border-bottom: 30px solid #f0eae4;
			border-top: 0;
			-moz-transform: rotate(306deg);
			-webkit-transform: rotate(306deg);
			-o-transform: rotate(306deg);
			-ms-transform: rotate(306deg);
			transform: rotate(306deg);
		}
	}

	#whatsappUrlCta {
		flex: 1;
		order:1;
		display: flex;
	    justify-content: center;
	    align-items: center;
		width: 50%;
		font-weight: normal;
		text-decoration: none;
		p {
			display: block;
			width: 80%;
			color:var(--wp-green);
			margin-bottom: 0;
		}
	}

	#whatsappUrlCta:hover {
		font-weight: normal;
	}

	#whatsappUrlQr {
		flex: 1;
		order:2;
		width: 50%;
		img {
			display: block;
			position:relative;
			width: 100%;
			height: auto;
		}
	}

	.whatsappClose {
		display: block;
	    position: absolute;
	    width: 40px;
	    height: 40px;
	    overflow: hidden;
	    background-color: #FFFFFF;
	    border: 1px #000000 solid;
	    top: -20px;
	    right: -20px;
	    text-indent: -9999px;
	    -webkit-border-radius: 50%;
		border-radius: 50%;
	}

	.whatsappClose:hover {
		font-weight: normal;
	}

	.whatsappClose:after {
		content:'';
		display: block;
		position:absolute;
		width: 20px;
		height: 20px;
		left:50%;
		top:50%;
		cursor: pointer;
		background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pjxzdmcgdmlld0JveD0iMCAwIDMyIDMyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxkZWZzPjxzdHlsZT4uY2xzLTF7ZmlsbDpub25lO3N0cm9rZTojMDAwO3N0cm9rZS1saW5lY2FwOnJvdW5kO3N0cm9rZS1saW5lam9pbjpyb3VuZDtzdHJva2Utd2lkdGg6MnB4O308L3N0eWxlPjwvZGVmcz48dGl0bGUvPjxnIGlkPSJjcm9zcyI+PGxpbmUgY2xhc3M9ImNscy0xIiB4MT0iNyIgeDI9IjI1IiB5MT0iNyIgeTI9IjI1Ii8+PGxpbmUgY2xhc3M9ImNscy0xIiB4MT0iNyIgeDI9IjI1IiB5MT0iMjUiIHkyPSI3Ii8+PC9nPjwvc3ZnPg==");
		background-repeat: no-repeat;
		background-position: center center;
		background-size:100%;
		-moz-transform: translateX(-50%) translateY(-50%);
		-webkit-transform: translateX(-50%) translateY(-50%);
		-o-transform: translateX(-50%) translateY(-50%);
		-ms-transform: translateX(-50%) translateY(-50%);
		transform: translateX(-50%) translateY(-50%);
	}
}

// balloon layout
.whatsappMessage.layout_03 {
	display: block;
	opacity: 0;
	visibility : hidden;
	position: fixed;
	z-index: 1401;
	width: 84%;
	min-width: 270px;
	max-width: 320px;
	height: auto;
	padding:var(--wp-03-padding);
	background-color: var(--wp-green);

	-webkit-border-radius: 20px;
	border-radius: 20px;

	-webkit-transition: all 300ms ease-in-out;
	-moz-transition: all 300ms ease-in-out;
	-ms-transition: all 300ms ease-in-out;
	-o-transition: all 300ms ease-in-out;
	transition: all 300ms ease-in-out;

	-webkit-box-shadow: 0 0 8px 0 rgba(0,0,0,0.3);
	box-shadow: 0 0 8px 0 rgba(0,0,0,0.3);

	&.right-bottom {
		right:calc(var(--wp-rightbottom-right) + 3vw);
		bottom:calc(var(--wp-rightbottom-bottom) + 80px);
	}

	&.left-bottom {
		left:calc(var(--wp-leftbottom-left) + 3vw);
		bottom:calc(var(--wp-rightbottom-bottom) + 20px);
	}

	&.right-bottom.active {
		right:calc(var(--wp-rightbottom-right) + 5vw);
	}

	&.left-bottom.active {
		left:calc(var(--wp-leftbottom-left) + 5vw);
	}

	&.right-bottom:before,
	&.left-bottom:before {
		content: '';
		display: block;
		position:absolute;
		z-index: -1;
		bottom: -17px;
		width: 0;
		height: 0;
		border-style: solid;
		border-right: 20px solid transparent;
		border-left: 20px solid transparent;
		border-bottom: 44px solid var(--wp-green);
		border-top: 0;
	}

	&.right-bottom:before {
		right: -14px;
		-moz-transform: rotate(138deg);
		-webkit-transform: rotate(138deg);
		-o-transform: rotate(138deg);
		-ms-transform: rotate(138deg);
		transform: rotate(138deg);
	}

	&.left-bottom:before {
		left: -14px;
		-moz-transform: rotate(228deg);
		-webkit-transform: rotate(228deg);
		-o-transform: rotate(228deg);
		-ms-transform: rotate(228deg);
		transform: rotate(228deg);
	}

	&.active {
		visibility : visible;
		opacity: 1;
	}

	#whatsappHeader, #whatsappFooter, #whatsappUrlQr, .whatsappText {
		display: none;
	}

	#whatsappUrlCta {
		display: block;
		position: relative;
		width:100%;
		min-height: 50px;
		background-color: #f0eae4;
		padding: 0.4em;
		text-decoration: none;
		-webkit-border-radius: 10px;
		border-radius: 10px;
		padding-top: 0.2em;
		padding-bottom: 0.2em;
		padding-left: 1.0em;
		padding-right: 1.0em;
		background-image: url('/images/icons/site-default/whatsapp-green.svg');
	    background-size: 40px 40px;
	    background-repeat: no-repeat;
	    background-position: 96% 50%;
		p {
			margin-bottom: 0;
			width: 88%;
		}
	}

	#whatsappUrlCta:hover {
		font-weight: normal;
	}

	.whatsappClose {
		color:#FFFFFF;
		text-decoration: none;
    	font-size: 0.8em;
	}

	.whatsappClose:hover {
		font-weight: normal;
	}
}